import { useMemo } from 'react'

//* HOCs
import { withLanguageContext } from 'context'

//* Components
import { Text, Image, CustomLink } from 'components/common'

//* Style
import BlogListStyle from './style'

const BlogList = ({ data, ...props }) => {
	const renderList = useMemo(() => {
		return data && data.length > 0 ? (
			data.map((i, k) => (
				<CustomLink url={`/blog/${i.slug}`} key={k}>
					<div className='FlexBox blogListItem cursorPointer'>
						<div className='listInfo FlexBoxColumn SpaceBetween'>
							<div>
								<Text tag={'pL'} className='pL PolySansNeutralRegular purpleLite subElements' text={i.category.name} />
								<Text tag={'h5'} className='h5 PolySansMedianRegular purple subElements' text={i.title} />
								<Text tag={'pL'} className='pL PolySansNeutralRegular purple subElements' text={i.featured_text} />
							</div>
							<div className='FlexBox date'>
								<Text
									tag={'pS'}
									className='pS PolySansNeutralRegular purple'
									text={`${i.reading_time} ${props.translate('minuteRead')}`}
								/>
								<Text tag={'pS'} className='pS PolySansNeutralRegular purple' text={i.date} />
							</div>
						</div>
						<div className='listImage'>
							<Image src={i.featured_image} alt='image' />
						</div>
					</div>
				</CustomLink>
			))
		) : (
			<div className='FlexBoxColumn AlignMiddle AlignCenter MessageWrap'>
				<Image src={'/images/svg/Group 43231.svg'} />
				<div className='description'>
					<Text tag={'span'} className='h6 PolySansNeutralRegular purple' text={'descNeutralRegular'} />
					&ensp;
					<Text tag={'span'} className='h6 PolySansMedianSemiBoldItalic purple' text={'descPolySansMedian'} />
				</div>
				<Text tag={'p'} className='pL PolySansNeutralRegular purple' text={'messageDesc'} />
			</div>
		)
	}, [data])

	return <BlogListStyle>{renderList}</BlogListStyle>
}

export default withLanguageContext(BlogList, ['translate'])
