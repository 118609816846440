import React, { useState } from 'react'
// Components
import { Text } from 'components/common'
import Collapse from '@mui/material/Collapse'

//* Style
import CollapseItemStyle from './style'

const CollapseItem = ({ data, ...props }) => {
	const [isActive, setIsActive] = useState(false)

	return (
		<CollapseItemStyle>
			<div className='collapseWrapper cursorPointer' onClick={() => {
							setIsActive(!isActive)
						}}>
				<div className={'accordion'}>
					<div
						className={'accordionSummary'}
					>
						<Text tag={'h6'} className={'h6 PolySansMedianRegular purple accordionTitle'} text={data.title} />
						<span className={`icon ${isActive ? 'active' : ''}`} />
					</div>
					<Collapse in={isActive} timeout='auto'>
						<Text tag={'pL'} className={'pL PolySansNeutralRegular purple collapseDesc'} text={data.desc} />
					</Collapse>
				</div>
			</div>
		</CollapseItemStyle>
	)
}

export default CollapseItem
