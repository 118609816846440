import styled from 'styled-components'

const CustomerTypeStyle = styled.div`
	--CustomerTypePadd: var(--sp11x);
	--CustomerInfoWidth: 47.5%;
	--imgWidth: calc(100% - var(--CustomerInfoWidth));

	--benefitsItemPadd: var(--sp8x);
	--benefitsItem: calc(33.333% - var(--benefitsItemPadd));

	--BenefitsWrapMarg: var(--sp7x);

	section {
		&:nth-child(1) {
			.CustomerType {
				background-color: var(--purpleMediumLite);
			}
		}
		&:nth-child(2) {
			.CustomerType {
				background-color: var(--greenLight);
				.btn {
					.backColor {
						background-color: var(--green);
						&:after {
							background-color: var(--purple);
						}
					}
				}
			}
		}
		&:nth-child(3) {
			.CustomerType {
				background-color: var(--orange);
			}
		}
	}

	.CustomerType {
		border-radius: var(--borderSm);
		.CustomerTypeTop {
			padding: var(--CustomerTypePadd) var(--CustomerTypePadd) var(--btnTopMarg) var(--CustomerTypePadd);
			.CustomerInfo {
				width: var(--CustomerInfoWidth);
				padding-right: var(--CustomerTypePadd);
				margin-bottom: var(--BenefitsWrapMarg);
				.comingSoon {
					margin-left: var(--sp1-5x);
				}
			}
			.videoCont{
				position: relative;
				video{
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					box-sizing: border-box;
				}
			}
			.imageCont,.videoCont {
				width: var(--imgWidth);
				padding-top: 33%;
			}
		}
		.BenefitsWrap {
			margin-top: var(--BenefitsWrapMarg);
			width: 100%;
			.FlexBoxContainer {
				width: 100%;
			}
			.benefitsItem {
				width: var(--benefitsItem);
				strong {
					font-style: normal;
					font-family: PolySansMedianRegular;
				}
				em {
					font-style: normal;
					font-family: PolySansNeutralItalic;
				}
			}
		}

		.btn .backColor {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		--CustomerTypePadd: var(--sp8x);

		--benefitsItemPadd: var(--sp7x);

		--BenefitsWrapMarg: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeXS}) {
		--CustomerTypePadd: var(--sp6x);

		--benefitsItemPadd: var(--sp4x);

		--BenefitsWrapMarg: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		--CustomerTypePadd: var(--sp5x);

		--benefitsItemPadd: var(--sp3x);

		--BenefitsWrapMarg: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		--CustomerTypePadd: var(--sp5x);
		--CustomerInfoWidth: 100%;
		--imgWidth: 100%;

		--benefitsItemPadd: var(--sp4x);
		--benefitsItem: calc(50% - var(--benefitsItemPadd));

		--BenefitsWrapMarg: var(--sp4x);

		.CustomerType {
			.BenefitsWrap {
				.benefitsItem {
					margin-bottom: var(--CustomerTypePadd);
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.CustomerTypeTop {
				.imageCont,.videoCont {
					padding-top: 62%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--CustomerTypePadd: var(--sp4x);
		--benefitsItemPadd: 0;
		--benefitsItem: 100%;
		--BenefitsWrapMarg: var(--sp3x);
	}
`
export default CustomerTypeStyle
