import styled from 'styled-components'

const BlogListStyle = styled.div`
	--pagePadRight: var(--sp15-5x);
	--listItemPad: var(--sp10x);
	--listInfoPadRight: var(--sp10x);
	--listItemMargBot: var(--sp6x);
	--listItemLastMargBot: var(--sp13x);
	--subElemMargBot: var(--sp5x);
	--iconWH: var(--sp18x);
	--iconMarB: var(--sp5x);
	--descMarB: var(--sp4x);

	padding: 0 var(--pagePadRight);

	.blogListItem {
		background-color: var(--white);
		padding: var(--listItemPad) var(--listItemPad);
		margin-bottom: var(--listItemMargBot);
		border: 2px solid var(--white);
		border-radius: 5px;

		&:last-child {
			margin-bottom: var(--listItemLastMargBot);
		}

		&:hover {
			border: 2px solid var(--purpleLite);
		}
	}

	.listInfo {
		width: 50%;
		padding-right: var(--listInfoPadRight);

		.subElements {
			margin-bottom: var(--subElemMargBot);
		}
	}

	.listImage {
		width: 50%;

		.imageCont {
			padding-top: 59%;
		}
	}

	.date p:first-child {
		&:after {
			content: '';
			border-right: 1px solid var(--purple);
			margin: 0 7px;
		}
	}

	.MessageWrap {
		min-height: 50vh;
		.imageCont {
			width: var(--iconWH);
			height: var(--iconWH);
			margin-bottom: var(--iconMarB);
		}
		.description {
			margin-bottom: var(--descMarB);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--pagePadRight: var(--sp11-5x);
		--listItemPad: var(--sp8x);
		--listItemMargBot: var(--sp4x);
		--listItemLastMargBot: var(--sp9x);
		--listInfoPadRight: var(--sp8x);
		--subElemMargBot: var(--sp4x);
		--iconWH: var(--sp13x);
		--iconMarB: var(--sp4x);
		--descMarB: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--pagePadRight: var(--sp7x);
		--listItemPad: var(--sp7x);
		--listItemMargBot: var(--sp3x);
		--listItemLastMargBot: var(--sp8x);
		--listInfoPadRight: var(--sp7x);
		--subElemMargBot: var(--sp3x);
		--iconWH: var(--sp10x);
		--iconMarB: var(--sp4x);
		--descMarB: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--pagePadRight: var(--sp6-5x);
		--listItemPad: var(--sp6x);
		--listItemMargBot: var(--sp3x);
		--listItemLastMargBot: var(--sp7x);
		--listInfoPadRight: var(--sp6x);
		--subElemMargBot: var(--sp3x);
		--iconWH: var(--sp10x);
		--iconMarB: var(--sp3x);
		--descMarB: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--pagePadRight: var(--sp5x);
		--listItemPad: var(--sp5x);
		--listItemMargBot: var(--sp3x);
		--listItemLastMargBot: var(--sp6x);
		--listInfoPadRight: var(--sp5x);
		--subElemMargBot: var(--sp2x);
		--iconWH: var(--sp10x);
		--iconMarB: var(--sp3x);
		--descMarB: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--pagePadRight: var(--sp4-5x);
		--listItemPad: var(--sp4x);
		--listItemMargBot: var(--sp3x);
		--listItemLastMargBot: var(--sp6x);
		--listInfoPadRight: var(--sp4x);
		--subElemMargBot: var(--sp2x);
		--iconWH: var(--sp10x);
		--iconMarB: var(--sp3x);
		--descMarB: var(--sp2x);
		.blogListItem {
			&:hover {
				border: 2px solid var(--white);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--pagePadRight: var(--sp2x);
		--listItemPadTop: var(--sp5x);
		--listItemPadRight: var(--sp4x);
		--subElemMargBot: var(--sp2x);
		--listItemLastMargBot: var(--sp5x);
		--listItemMargBot: var(--sp3x);
		--iconWH: var(--sp8x);
		--iconMarB: var(--sp3x);
		--descMarB: var(--sp2x);

		.blogListItem {
			display: block;
			padding: var(--listItemPadTop) var(--listItemPadRight);
			&:hover {
				border: 2px solid var(--white);
			}
		}

		.listInfo {
			width: 100%;
			div:first-child p:last-child {
				margin-bottom: var(--listItemPadTop);
			}
		}

		.listImage {
			width: 100%;
		}

		.date {
			margin-bottom: var(--listItemPadTop);
		}

		.MessageWrap .description {
			max-width: 350px;
			text-align: center;
		}
	}
`
export default BlogListStyle
