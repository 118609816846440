import styled from 'styled-components'

const LookingHireStyle = styled.div`
	--titleMarg: var(--sp7x);
	--itemPaddingTop: var(--sp13x);
	--itemPaddingLeft: var(--sp8x);
	--imgWrapPadd: var(--sp8x);
	--imgSize: var(--sp13x);
	--autorWrapMarg: var(--sp7x);

	.titleWrap {
		margin-bottom: var(--titleMarg);
	}

	.swiper-container {
		padding-bottom: var(--itemPaddingTop);
	}

	.swiper-pagination {
		position: absolute;
		text-align: center;
		transition: 0.3s opacity;
		transform: translate3d(0, 0, 0);
		z-index: 10;

		.swiper-pagination-bullet {
			width: 13px;
			height: 13px;
			display: inline-block;
			border-radius: 100%;
			background: var(--greyLight);
			cursor: pointer;
			margin: 0 var(--sp1-5x);
            opacity: 1;
			&.swiper-pagination-bullet-active {
				background: var(--purple);
			}
		}
	}

	.swiper-container-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: 0px;
		left: 0;
		width: 100%;
	}
	.swiper-slide{
		height:auto;
		.sliderItem {
			height: 100%;
			border-radius: var(--borderSm);
			background-color: var(--white);
			padding: var(--itemPaddingTop) var(--itemPaddingLeft);
			.imgWrap {
				padding: var(--imgWrapPadd) 0;
				.imageCont {
					padding: 3% 15%;
					img {
						object-fit: contain;
					}
				}
			}
			.autorWrap {
				width: 100%;
				margin-top: var(--autorWrapMarg);

				.imageCont {
					margin-right: var(--sp4x);
					width: var(--imgSize);
					height: var(--imgSize);
					overflow: hidden;
					border-radius: 100px;
				}

				.autorInfo {
					.h6 {
						margin-bottom: var(--sp3x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarg: var(--sp6x);

		--itemPaddingTop: var(--sp9x);
		--itemPaddingLeft: var(--sp7x);
		--imgWrapPadd: var(--sp7x);
		--autorWrapMarg: var(--sp6x);
		--imgSize: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) {
		.swiper-pagination {
			.swiper-pagination-bullet {
				width: 10px;
				height: 10px;
				margin: 0 var(--spSize);
			}
		}

		.sliderItem {
			.autorWrap {
				.imageCont {
					margin-right: var(--sp3x);
				}
				.autorInfo {
					.h6 {
						margin-bottom: var(--sp2x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarg: var(--sp5x);

		--itemPaddingTop: var(--sp8x);
		--itemPaddingLeft: var(--sp6x);
		--imgWrapPadd: var(--sp6x);
		--autorWrapMarg: var(--sp5x);
		--imgSize: var(--sp9x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMarg: var(--sp5x);

		--itemPaddingTop: var(--sp7x);
		--itemPaddingLeft: var(--sp5x);
		--imgWrapPadd: var(--sp5x);
		--autorWrapMarg: var(--sp4x);
		--imgSize: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--titleMarg: var(--sp4x);

		--itemPaddingTop: var(--sp6x);
		--itemPaddingLeft: var(--sp3x);
		--imgWrapPadd: var(--sp4x);
		--autorWrapMarg: var(--sp4x);
		--imgSize: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--titleMarg: var(--sp4x);

		--itemPaddingTop: var(--sp6x);
		--itemPaddingLeft: var(--sp3x);
		--imgWrapPadd: var(--sp4x);
		--autorWrapMarg: var(--sp4x);
		--imgSize: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMarg: var(--sp4x);

		--itemPaddingTop: var(--sp5x);
		--itemPaddingLeft: var(--sp3x);
		--imgWrapPadd: var(--sp4x);
		--autorWrapMarg: var(--sp4x);
		--imgSize: var(--sp7x);
	}
`
export default LookingHireStyle
