import React, { memo as Memo, useState, useRef, useEffect, useMemo, useCallback } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Container, Title, Text, LottieAnimation, AnimationWrapp } from 'components/common'

import HowItWorksStyle from './style'

import postApplay from 'lottieAnimation/home/postApplay.json'
import matching from 'lottieAnimation/home/matching.json'
import procesing from 'lottieAnimation/home/procesing.json'

const HowItWorksSection = Memo(({ data, preloader, selectedLang, ...props }) => {
	const Lottie = [postApplay, matching, procesing]

	//! States
	const [secObj, setSecObj] = useState()
	const [lottieObj, setLottieObj] = useState([])
	const [identificator, setIdentificator] = useState(0)

	//! Refs
	const items = useRef([])

	useEffect(() => {
		if (data && items?.current.length) {
			setSecObj(items.current)
		}
	}, [items, data, selectedLang])

	const startAnimeArr = useCallback(
		(x) => {
			setLottieObj(x)
			setIdentificator(x.length)
		},
		[identificator]
	)

	const itemsLottie = useMemo(() => {
		if (data.items.length) {
			return data.items.map((item, ind) => {
				return (
					<div className='items' key={ind}>
						<LottieAnimation animData={Lottie[ind]} isPlay={lottieObj[ind]} autoplay={false} loop={true} className='titleMaxMargBot' />
						<Text tag={'h4'} className={'titleMinMargBot PolySansNeutralRegular purple'} text={item.title} />
						<Text className={'pL PolySansNeutralRegular purple'} text={item.text} ref={(el) => (items.current[ind] = el)} />
					</div>
				)
			})
		}
	}, [data, lottieObj, identificator])

	return data ? (
		<Container className='containerPaddBottom relativeWrap'>
			<AnimationWrapp state={secObj} startAnimeArr={startAnimeArr}>
				<HowItWorksStyle className='FlexBoxContainer SpaceBetween'>
					<div className='InfoWrapp'>
						<div>
							<Title className={'titleMaxMargBot purple'} text={['howIt', 'works']} />
							<Text className={'h6 PolySansNeutralRegular purple'} text={data.descripion} />
						</div>
					</div>
					<div className='ItemsWrap'>{itemsLottie}</div>
				</HowItWorksStyle>
			</AnimationWrapp>
		</Container>
	) : (
		''
	)
})

export default withLanguageContext(HowItWorksSection, ['selectedLang'])
