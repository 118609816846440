import React, { memo as Memo, useRef, useState, useMemo } from 'react'

import { gsap } from 'gsap'

//Hooks
import { withUIContext } from 'context'

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react'

//* Components
import { Container, Title, Text, Image } from 'components/common'

//* Style
import SliderStyle from './style'

// import Swiper core and required modules
import SwiperCore, { Pagination } from 'swiper'

// install Swiper modules
SwiperCore.use([Pagination])

const SliderSection = Memo(({ data, preloader, ...props }) => {
	const [sliderState, setSliderState] = useState()
	const [sliderActive, setSliderActive] = useState(true)

	const paddingTimeLine = useMemo(() => new gsap.timeline({ ease: 'power3.out' }), [])

	//! Ref's
	const sliderRef = useRef()

	//! Animation Updata
	const slidePrev = () => {
		if (sliderActive) {
			if (sliderState.activeIndex !== 0) {
				sliderRef.current.swiper.slidePrev()
				setSliderActive(false)

				if (props.winWidth >= 1024) {
					let activeSlide = sliderState.activeIndex
					let previousSlide = props.winWidth >= 1920 ? 4 : 3
					let arr = []

					sliderState.slides.forEach((item, ind) => {
						if (ind >= activeSlide && ind <= activeSlide + previousSlide) {
							arr.push(item)
							return arr
						}
					})

					arr = arr.reverse()

					paddingTimeLine
						.to(arr, 0.4, {
							stagger: 0.01,
							marginLeft: '30px',
							marginRight: '30px',
							delay: -0.03,
						})
						.to(arr, 0.4, {
							stagger: 0.01,
							marginLeft: 0,
							marginRight: 0,
						})
				}
			}

			setTimeout(() => {
				setSliderActive(true)
			}, 1000)
		}
	}

	const slideNext = () => {
		if (sliderActive) {
			if (!sliderState.isEnd) {
				sliderRef.current.swiper.slideNext()
				setSliderActive(false)

				if (props.winWidth >= 678) {
					let activeSlide = sliderState.activeIndex
					let previousSlide = props.winWidth >= 1920 ? 4 : 3
					let arr = []

					sliderState.slides.forEach((item, ind) => {
						if (ind >= activeSlide - previousSlide && ind <= activeSlide + previousSlide) {
							arr.push(item)
							return arr
						}
					})

					paddingTimeLine
						.to(arr, 0.4, {
							stagger: 0.01,
							marginLeft: '30px',
							marginRight: '30px',
							delay: -0.03,
						})
						.to(arr, 0.4, {
							stagger: 0.01,
							marginLeft: '0px',
							marginRight: '0px',
						})
				}
			}

			setTimeout(() => {
				setSliderActive(true)
			}, 1000)
		}
	}

	return data ? (
		<Container className='containerPaddBottom '>
			<SliderStyle>
				<Title className={'titleWrap purple'} text={['usecasesAnd', 'testimonials']} />
				<div className='sliderSection  relativeWrap'>
					<Swiper
						onSwiper={(swiper) => setSliderState(swiper)}
						ref={sliderRef}
						speed={1000}
						slidesPerView={2}
						slidesPerGroup={2}
						spaceBetween={32}
						pagination={{ clickable: true }}
						breakpoints={{
							300: {
								slidesPerView: 1,
								slidesPerGroup: 1,
								spaceBetween: 24,
							},
							1024: {
								slidesPerView: 2,
								slidesPerGroup: 2,
								spaceBetween: 24,
							},
						}}
					>
						{data.map((item, ind) => {
							return (
								<SwiperSlide key={ind}>
									<div className='sliderItem FlexBoxColumn SpaceBetween'>
										<div>
											<Text className={'h6 PolySansMedianRegular purple titleMinMargBot'} text={item.title} />
											<Text className={'pL PolySansNeutralRegular purple'} text={item.text} />
											<div className='FlexBox JustifyEnd imgWrap'>
												<Image src={item.img} alt='brand_image' />
											</div>
											<Text className={'h6 PolySansNeutralItalic purple'} text={item.quote} />
										</div>
										<div className='FlexBoxContainer autorWrap'>
											<Image src={item.autorImg} alt='ellipse_image' />
											<div>
												<Text className={'h6 PolySansNeutralRegular purple'} text={item.autorName} />
												<Text className={'pM PolySansSlimLightItalic purple'} text={item.autorPosition} />
											</div>
										</div>
									</div>
								</SwiperSlide>
							)
						})}
					</Swiper>
				</div>
			</SliderStyle>
		</Container>
	) : (
		''
	)
})

export default withUIContext(SliderSection, ['winWidth', 'screenSizes'])
