import styled from 'styled-components'

const LatestPressStyle = styled.div`
	--secLeftWidth: 46.1%;
	--secRightWidth: calc(100% - var(--secLeftWidth) - var(--sp16x));
	--wrapPaddingTop: var(--sp23x);
	--wrapPaddingLeft: var(--sp16x);
	--leftDescMarginBottom: var(--sp13x);
	--subDescMarginBottom: var(--sp4x);

	margin-top: var(--containerMarginTop);
	background-color: var(--white);
	border-radius: var(--borderSm);
	padding: var(--wrapPaddingTop) var(--wrapPaddingLeft);

	.titleWrap {
		width: 100%;
	}

	.hidden {
		visibility: hidden;
	}

	.leftSec {
		width: var(--secLeftWidth);
		z-index: 1;
		.contentWrap {
			flex: 1;
			.textContent {
				height: 100%;
				justify-content: space-between;
				.leftDesc {
					margin-bottom: var(--leftDescMarginBottom);
				}
				.subDesc {
					margin-bottom: var(--subDescMarginBottom);
				}
			}
		}
	}

	.rightSection {
		width: var(--secRightWidth);
		z-index: 1;
		.contentWrap {
			flex: 1;
			.textContent {
				height: 100%;
				justify-content: space-between;
				.rightTitle {
					margin-bottom: var(--leftDescMarginBottom);
				}
				.subDesc {
					margin-bottom: var(--subDescMarginBottom);
				}
			}
		}
	}

	.overflowHidden {
		position: absolute;
		top: -50%;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
		.imageCont {
			position: absolute;
			img {
				object-fit: contain !important;
			}
			&:nth-child(1) {
				padding: 9% 11%;
				bottom: 0;
				left: 0;
				transform: translate(2%, 100%);
			}
			&:nth-child(2) {
				padding: 8% 15%;
				top: 30%;
				left: 50%;
				transform: rotate(10deg) translate(25%, 170%);
			}
			&:nth-child(3) {
				padding: 11%;
				bottom: -15%;
				right: 0;
				transform: translate(-50%, 60%);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--secLeftWidth: 44%;
		--secRightWidth: 44%;
		--wrapPaddingTop: var(--sp16x);
		--wrapPaddingLeft: var(--sp8x);

		--leftDescMarginBottom: var(--sp9x);
		--subDescMarginBottom: var(--sp3x);

		.overflowHidden {
			.imageCont {
				&:nth-child(1) {
					padding: 8.6% 12%;
				}
				&:nth-child(2) {
					padding: 9% 18%;
				}
				&:nth-child(3) {
					padding: 10.1%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--secLeftWidth: 45%;
		--secRightWidth: 48%;
		--wrapPaddingTop: var(--sp13x);
		--wrapPaddingLeft: var(--sp6x);

		--leftDescMarginBottom: var(--sp8x);
		--subDescMarginBottom: var(--sp3x);

		.overflowHidden {
			.imageCont {
				&:nth-child(1) {
					padding: 11% 8%;
				}
				&:nth-child(2) {
					padding: 9% 16%;
				}
				&:nth-child(3) {
					padding: 11.3%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--secLeftWidth: 47%;
		--secRightWidth: 46.5%;
		--wrapPaddingTop: var(--sp10x);
		--wrapPaddingLeft: var(--sp8x);

		--leftDescMarginBottom: var(--sp7x);
		--subDescMarginBottom: var(--sp3x);

		.overflowHidden {
			.imageCont {
				&:nth-child(1) {
					padding: 7% 10%;
				}
				&:nth-child(2) {
					padding: 8% 15%;
				}
				&:nth-child(3) {
					padding: 11%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--secLeftWidth: 52%;
		--secRightWidth: 42%;
		--wrapPaddingTop: var(--sp8x);
		--wrapPaddingLeft: var(--sp5x);

		--leftDescMarginBottom: var(--sp6x);
		--subDescMarginBottom: var(--sp2x);

		.overflowHidden {
			.imageCont {
				&:nth-child(1) {
					padding: 9% 11%;
				}
				&:nth-child(2) {
					padding: 9% 17%;
				}
				&:nth-child(3) {
					padding: 13.5%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--secLeftWidth: 52%;
		--secRightWidth: 42%;
		--wrapPaddingTop: var(--sp12x);
		--wrapPaddingLeft: var(--sp5x);

		--leftDescMarginBottom: var(--sp6x);
		--subDescMarginBottom: var(--sp2x);

		.overflowHidden {
			.imageCont {
				&:nth-child(1) {
					padding: 12% 15%;
				}
				&:nth-child(2) {
					padding: 15% 19%;
				}
				&:nth-child(3) {
					padding: 20%;
					transform: translate(0, 20%);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--secLeftWidth: 90%;
		--secRightWidth: 90%;
		--wrapPaddingTop: var(--sp10x);
		--wrapPaddingLeft: var(--sp4x);

		--leftDescMarginBottom: var(--sp4x);
		--subDescMarginBottom: var(--sp2x);

		.overflowHidden {
			.imageCont {
				&:nth-child(1) {
					padding: 17% 22%;
					transform: translate(-40%, -100%);
				}
				&:nth-child(2) {
					padding: 16% 23%;
					transform: translate(-10%, 20%) rotate(10deg);
				}
				&:nth-child(3) {
					padding: 22%;
					transform: translate(15%, 15%);
				}
			}
		}
	}
`
export default LatestPressStyle
