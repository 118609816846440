import React, { memo as Memo } from 'react'

//* Components
import { Text, Image, CustomLink } from 'components/common'

//* Style
import RelatedSectionStyle from './style'

const RelatedSection = Memo(({ relatedData, ...props }) => {
	return (
		<RelatedSectionStyle>
			<div className='relatedTitle'>
				<Text tag={'h4'} className='h4 purple PolySansMedianSemiBoldItalic' text={'Related'} />
			</div>

			<div className='FlexBoxColumn relatedList'>
				{relatedData &&
					relatedData.map((i, k) => {
						return (
							<div className='relatedListItem cursorPointer' key={k}>
								<CustomLink url={`/blog/${i.slug}`}>
									<Image src={i.featured_image} alt='Related Image' />
									<Text tag={'pL'} className='pL purpleLite PolySansNeutralRegular filterTitle' text={i.category.name} />
									<Text tag={'h6'} className='pL purple PolySansMedianRegular listTitle' text={i.title} />
								</CustomLink>
							</div>
						)
					})}
			</div>
		</RelatedSectionStyle>
	)
})

export default RelatedSection
