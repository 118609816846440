import React, { memo as Memo, useCallback } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { Button, Container, Title, Text, LottieAnimation, RequestPopup } from 'components/common'

import HeroStyle from './style'
import aboutLottie from 'lottieAnimation/about/aboutHero.json'

const AboutUsHeroSection = Memo(({ data, preloader, openPopup, ...props }) => {
	const openPopupFun = useCallback(() => {
		openPopup(<RequestPopup />)
	}, [])
	return data ? (
		<Container className='containerPaddBottom containerPaddTop'>
			<HeroStyle className='FlexBoxContainer '>
				<div className='LeftAboutSection'>
					<div className='FlexBoxContainer'>
						<Text tag={'h1'} className={'h1 purple PolySansMedianRegular'} text={'WereEnabling'} />
						&nbsp;&nbsp;
						<Text tag={'h1'} className={'h1 purple PolySansNeutralItalic'} text={'Digital'} />
						<Text tag={'h1'} className={'h1 purple PolySansNeutralItalic'} text={'Transformation'} />
						&nbsp;&nbsp;
						<Text tag={'h1'} className={'h1 purple PolySansMedianRegular'} text={'With'} />
						<Text tag={'h1'} className={'h1 purple PolySansMedianRegular'} text={'WorldClassSuppliers'} />
					</div>
					<Text className={'description pL PolySansNeutralRegular purple'} text={data.text} />

					<Button onClick={openPopupFun} className={'purple'} wrapClassName={'btnContTopMarg'}>
						<Title type={'btn'} text={['requestAn', 'onboarding']} fontSize={'pM'} className={'whiteDark'} />
					</Button>
				</div>
				<div className='RightAboutSection'>
					<LottieAnimation animData={aboutLottie} isPlay={!props.preloader} autoplay={false} loop={true} />
				</div>
			</HeroStyle>
		</Container>
	) : null
})

export default withLanguageContext(withUIContext(AboutUsHeroSection, ['winWidth', 'screenSizes', 'preloader', 'openPopup']), ['selectedLang'])
