import styled from 'styled-components'

const DataSecurityStyle = styled.div`
	--titleMarg: var(--sp7x);
	--itemSpace: var(--sp4x);
	--itemWidth: calc(33.333% - (var(--itemSpace) / 2));
	--itemPadding: var(--sp8x);
	--itemPaddingLeft: var(--sp8x);

	.titleWrap {
		margin-bottom: var(--titleMarg);
	}
	.ItemsWrap {
		.items {
			padding: var(--itemPadding) var(--itemPaddingLeft);
			margin-bottom: var(--itemSpace);
			background-color: var(--white);
			border-radius: var(--borderSm);
			width: var(--itemWidth);
			.LottieWrapp {
				padding-top: 65%;
			}

			&:nth-last-child(-n + 3) {
				margin-bottom: 0;
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarg: var(--sp6x);
		--itemSpace: var(--sp3x);
		--itemPadding: var(--sp7x);
		--itemPaddingLeft: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarg: var(--sp5x);
		--itemSpace: var(--sp3x);
		--itemPadding: var(--sp6x);
		--itemPaddingLeft: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMarg: var(--sp5x);
		--itemSpace: var(--sp2-5x);
		--itemPadding: var(--sp5x);
		--itemPaddingLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--titleMarg: var(--sp4x);
		--itemSpace: var(--sp3x);
		--itemPadding: var(--sp6x);
		--itemPaddingLeft: var(--sp3x);
		--itemWidth: calc(50% - var(--itemSpace));

		.ItemsWrap {
			.items {
				&:nth-last-child(-n + 3) {
					margin-bottom: var(--sp3x);
				}
				&:nth-last-child(-n + 2) {
					margin-bottom: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--titleMarg: var(--sp4x);
		--itemSpace: var(--sp3x);
		--itemPadding: var(--sp6x);
		--itemPaddingLeft: var(--sp3x);
		--itemWidth: calc(50% - var(--itemSpace));

		.ItemsWrap {
			.items {
				&:nth-last-child(-n + 3) {
					margin-bottom: var(--sp2-5x);
				}
				&:nth-last-child(-n + 2) {
					margin-bottom: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMarg: var(--sp4x);
		--itemSpace: var(--sp3x);
		--itemPadding: var(--sp5x);
		--itemPaddingLeft: var(--sp4x);
		--itemWidth: 100%;

		.ItemsWrap {
			.items {
				&:nth-last-child(-n + 3) {
					margin-bottom: var(--sp3x);
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
`
export default DataSecurityStyle
