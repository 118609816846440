import styled from 'styled-components'

const RelatedSectionStyle = styled.div`
	width: var(--relatedWidth);
	--relatedTitleMargTopBot: var(--sp4x);
	--imageMargTop: var(--sp6x);
	--relatedTitlePadLeftRight: var(--sp5x);
	--imageMargBot: var(--sp4x);
	--filterTitleMargBot: var(--sp3x);
	--listTitleMargBot: var(--sp6x);
	--relatedListMargTop: var(--sp12-5x);

	border-radius: 5px;

	.relatedTitle {
		background-color: var(--white);
		border: 1px solid var(--white);
		border-radius: 5px 5px 0 0;

		h4 {
			padding: var(--relatedTitleMargTopBot) var(--relatedTitlePadLeftRight) 0;
		}
	}

	.relatedList {
		background-color: var(--white);
		border: 1px solid var(--white);
		padding: 0 var(--relatedTitlePadLeftRight);

		.relatedListItem {
			border-bottom: 1px solid var(--purple);
			&:last-child {
				border-bottom: none;
			}
		}

		.imageCont {
			padding-top: 63%;
			margin-bottom: var(--imageMargBot);
			margin-top: var(--imageMargTop);
		}

		.filterTitle {
			margin-bottom: var(--filterTitleMargBot);
		}

		.listTitle {
			margin-bottom: var(--listTitleMargBot);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--relatedTitleMargTopBot: var(--sp3x);
		--imageMargTop: var(--sp5x);
		--relatedTitlePadLeftRight: var(--sp4x);
		--imageMargBot: var(--sp3x);
		--filterTitleMargBot: var(--sp2x);
		--listTitleMargBot: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--relatedTitleMargTopBot: var(--sp2x);
		--imageMargTop: var(--sp4x);
		--relatedTitlePadLeftRight: var(--sp4x);
		--imageMargBot: var(--sp2x);
		--filterTitleMargBot: var(--sp2x);
		--listTitleMargBot: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--relatedTitleMargTopBot: var(--sp2x);
		--imageMargTop: var(--sp4x);
		--relatedTitlePadLeftRight: var(--sp4x);
		--imageMargBot: var(--sp2x);
		--filterTitleMargBot: var(--sp2x);
		--listTitleMargBot: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--relatedTitleMargTopBot: var(--sp4x);
		--relatedTitlePadLeftRight: var(--sp4x);
		--imageMargBot: var(--sp2x);
		--imageMargTop: var(--sp4x);
		--filterTitleMargBot: var(--sp2x);
		--listTitleMargBot: var(--sp4x);

		.relatedTitle {
			margin-top: var(--relatedListMargTop);
		}

		.relatedList {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;

			.relatedListItem {
				width: calc(50% - (8px * 2));
				border-bottom: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--relatedTitleMargTopBot: var(--sp4x);
		--relatedTitlePadLeftRight: var(--sp4x);
		--imageMargBot: var(--sp2x);
		--imageMargTop: var(--sp4x);
		--filterTitleMargBot: var(--sp2x);
		--listTitleMargBot: var(--sp4x);

		.relatedTitle {
			margin-top: var(--relatedListMargTop);
		}

		.relatedList {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;

			.relatedListItem {
				width: calc(50% - (8px * 2));
				border-bottom: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--relatedListMargTop: var(--sp10x);
		--relatedTitleMargTopBot: var(--sp4x);
		--imageMargTop: var(--sp3x);
		--relatedTitlePadLeftRight: var(--sp4x);
		--imageMargBot: var(--sp2x);
		--filterTitleMargBot: var(--sp2x);
		--listTitleMargBot: var(--sp3x);

		.relatedTitle {
			margin-top: var(--relatedListMargTop);
		}
	}
`
export default RelatedSectionStyle
