import styled from 'styled-components'

const TitleSectionStyle = styled.div`
	--backToBtnItemsMargLeft: var(--sp2-5x);
	--backToBtnTop: var(--sp16x);
	--subTitlePadLeft: var(--sp26x);
	--subTitlePadRight: var(--sp72x);
	--subTitleMargBot: var(--sp7x);
	--iconSize: var(--sp2-25x);

	margin-bottom: var(--subTitleMargBot);

	.backToBlock {
		position: absolute;
		left: var(--subTitlePadLeft);
		top: calc(var(--backToBtnTop) * (-1));

		i {
			font-size: var(--iconSize);
		}

		p {
			margin-left: var(--backToBtnItemsMargLeft);
		}

		.backBtn {
			&:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: var(--purple);
				transform: translateY(-100%);
			}
			&:hover {
				&:after {
					animation: monFrame var(--trTime2) linear;
				}
			}
		}

		@keyframes monFrame {
			0% {
				width: 0%;
			}

			100% {
				width: 100%;
			}
		}
	}

	.subTitle {
		padding-left: var(--subTitlePadLeft);
		padding-right: var(--subTitlePadRight);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--backToBtnItemsMargLeft: var(--sp2x);
		--backToBtnTop: var(--sp11x);
		--subTitlePadLeft: var(--sp19-5x);
		--subTitlePadRight: var(--sp53-5x);
		--subTitleMargBot: var(--sp6x);
		--iconSize: var(--sp1-75x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--backToBtnItemsMargLeft: var(--sp2x);
		--backToBtnTop: var(--sp9x);
		--subTitlePadLeft: var(--sp14x);
		--subTitlePadRight: var(--sp30x);
		--subTitleMargBot: var(--sp5x);
		--iconSize: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--backToBtnItemsMargLeft: var(--sp2x);
		--backToBtnTop: var(--sp8x);
		--subTitlePadLeft: var(--sp10x);
		--subTitlePadRight: var(--sp24x);
		--subTitleMargBot: var(--sp5x);
		--iconSize: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--backToBtnItemsMargLeft: var(--sp1x);
		--backToBtnTop: var(--sp8x);
		--subTitlePadLeft: var(--sp9x);
		--subTitlePadRight: var(--sp23-5x);
		--subTitleMargBot: var(--sp4x);
		--iconSize: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--backToBtnItemsMargLeft: var(--sp1x);
		--backToBtnTop: var(--sp8x);
		--subTitlePadLeft: var(--sp4-5x);
		--subTitlePadRight: var(--sp4-5x);
		--subTitleMargBot: var(--sp4x);
		--iconSize: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--backToBtnItemsMargLeft: var(--sp1x);
		--backToBtnTop: var(--sp6x);
		--subTitlePadLeft: var(--sp2x);
		--subTitlePadRight: var(--sp2x);
		--subTitleMargBot: var(--sp4x);
		--iconSize: var(--sp1-5x);
	}
`
export default TitleSectionStyle
