import React, { memo as Memo, useCallback, useState, useEffect, useMemo, useRef } from 'react'
//* GSAP
import { gsap } from 'gsap'

import { withUIContext } from 'context'

//* Components
import { Container, Text } from 'components/common'

//* Pages
import { PricingItem } from 'components/pages'

//* Style
import PricingFilterStyle from './style'

//* Data
import data from './data.json'

const PricingFilter = Memo(({ preloader, ...props }) => {
	//! State
	const [activeIndex, setActiveIndex] = useState('buyer')
	const [filterActive, setFilterActive] = useState(false)

	const itemsRef = useRef()

	const tl = gsap.timeline({ duration: 0, ease: 'none' })

	const onActiveChange = useCallback(
		(x) => {
			setActiveIndex(x)
		},
		[activeIndex]
	)

	useEffect(() => {
		if (!preloader) {
			setFilterActive(true)

			if (itemsRef.current) {
				tl.fromTo(
					itemsRef.current.childNodes,
					0.5,
					{
						duration: 0,
						opacity: 0,
						y: '50%',
					},
					{
						stagger: 0.1,
						opacity: 1,
						y: 0,
					}
				)
			}
		}
	}, [preloader, activeIndex])

	const items = useMemo(() => {
		return (
			<div className='FlexBoxContainer SpaceBetween secondSection' ref={itemsRef}>
				<div className='PricingListItem FlexBoxColumn '>
					<PricingItem data={data[activeIndex].types.basic} />
				</div>
				<div className='PricingListItem FlexBoxColumn '>
					<PricingItem data={data[activeIndex].types.pro} />
				</div>
			</div>
		)
	}, [activeIndex])

	return (
		<Container>
			<PricingFilterStyle className={filterActive ? 'activeAnimation' : ''}>
				<div className='FlexBox AlignCenter filterWrap '>
					<div className='FlexBox filteredList relativeWrap'>
						<div className='pricingFilterItem cursorPointer' onClick={() => onActiveChange('buyer')}>
							<Text tag={'pL'} className={'pL PolySansMedianRegular purple uppercase'} text={data.buyer.title} />
						</div>
						<div className='pricingFilterItem cursorPointer' onClick={() => onActiveChange('supplier')}>
							<Text tag={'pL'} className={'pL PolySansMedianRegular purple uppercase'} text={data.supplier.title} />
						</div>
						<div className={`filteredListDouble overflowHidden  ${activeIndex == 'supplier' ? 'active' : ''}`}>
							<div className='FlexBox filteredList'>
								<div className='pricingFilterItem'>
									<Text tag={'pL'} className={'pL PolySansMedianRegular purpleLite uppercase'} text={data.buyer.title} />
								</div>
								<div className='pricingFilterItem'>
									<Text tag={'pL'} className={'pL PolySansMedianRegular purpleLite uppercase'} text={data.supplier.title} />
								</div>
							</div>
						</div>
					</div>
				</div>
				{items}
			</PricingFilterStyle>
		</Container>
	)
})

export default withUIContext(PricingFilter, ['preloader'])
