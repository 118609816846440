import styled from 'styled-components'

const PricingCollapseStyle = styled.div`
	--QAWrapWidth: 22.2%;
	--collapseWidth: 57.2%;
	--QAWrapTitleMB: var(--sp5x);
	--contactUsWrapMT: var(--sp8x);

	.QAWrap {
		width: var(--QAWrapWidth);

		& div {
			position: sticky;
			top: 5%;
		}

		.QAWrapTitle {
			margin-bottom: var(--QAWrapTitleMB);
		}
	}

	.CollapseWrap {
		width: var(--collapseWidth);
		.contactUsWrap {
			margin-top: var(--contactUsWrapMT);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--QAWrapWidth: 23.8%;
		--collapseWidth: 57.4%;
		--QAWrapTitleMB: var(--sp4x);
		--contactUsWrapMT: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--QAWrapWidth: 23.7%;
		--collapseWidth: 59.1%;
		--QAWrapTitleMB: var(--sp3x);
		--contactUsWrapMT: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--QAWrapWidth: 25.8%;
		--collapseWidth: 59.3%;
		--QAWrapTitleMB: var(--sp3x);
		--contactUsWrapMT: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--QAWrapWidth: 27.3%;
		--collapseWidth: 61.4%;
		--QAWrapTitleMB: var(--sp2x);
		--contactUsWrapMT: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--QAWrapWidth: 100%;
		--collapseWidth: 100%;
		--QAWrapTitleMB: var(--sp2x);
		--contactUsWrapMT: var(--sp5x);

		.qaDesc {
			margin-bottom: var(--sp6x);
		}

		.QAWrap {
			& div {
				margin-bottom: var(--sp6x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--QAWrapWidth: 100%;
		--collapseWidth: 100%;
		--QAWrapTitleMB: var(--sp2x);
		--contactUsWrapMT: var(--sp5x);

		.qaDesc {
			margin-bottom: var(--sp5x);
		}

		.QAWrap {
			& div {
				margin-bottom: var(--sp6x);
			}
		}
	}
`
export default PricingCollapseStyle
