import React, { memo as Memo } from 'react'

//* Components
import { Container, Text } from 'components/common'

//* Style
import ImprintPageStyle from './style'

const ImprintPage = Memo(({ imprintsFakeData, ...props }) => {
	return imprintsFakeData ? (
		<Container>
			<ImprintPageStyle>
				<Text tag={'h2'} className={'h2 PolySansMedianSemiBoldItalic purple pageTitle'} text={imprintsFakeData?.title} />
				<div>
					{imprintsFakeData?.firstSection?.map((i, k) => {
						return (
							<div key={k}>
								<Text tag={'h5'} className={'h5 PolySansMedianRegular purple sectionTitleMargBot'} text={i.title} />
								<div>
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compName} />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compAddress} />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compMobile} />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compEmail} />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compWeb} />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compRegCourt} />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compHRBNum} />
									<br />
									<br />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compAuthorized} />
								</div>
							</div>
						)
					})}
				</div>
				<div>
					{imprintsFakeData?.secondSection?.map((i, k) => {
						return (
							<div key={k}>
								<Text tag={'h5'} className={'h5 PolySansMedianRegular purple sectionTitleMargBot'} text={i.title} />
								<div>
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compAuthorized} />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compName} />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compAddress} />
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.compMobile} />
								</div>
							</div>
						)
					})}
				</div>
				<div>
					{imprintsFakeData?.lastSection?.map((i, k) => {
						return (
							<div key={k}>
								<Text tag={'h5'} className={'h5 PolySansMedianRegular purple  sectionTitleMargBot'} text={i.title} />
								<div>
									<Text tag={'pM'} className={'pM PolySansNeutralRegular purple'} text={i.desc} />
								</div>
							</div>
						)
					})}
				</div>
			</ImprintPageStyle>
		</Container>
	) : (
		''
	)
})

export default ImprintPage
