import styled from 'styled-components'

const OurVisionStyle = styled.div`
	--descriptionMargin: var(--sp5x) 0 var(--containerMarginTop) 0;
	margin-top: var(--containerMarginTop);

	.description {
		margin: var(--descriptionMargin);
	}
	.danger {
		p {
			font-size: var(--h5);
			color: var(--purple);
			font-family: PolySansMedianRegular;

			span {
				position: relative;
				z-index: 1;
				white-space: nowrap;

				&:after {
					position: absolute;
					content: '';
					background-image: url('/images/svg/magnetBorderBottomGreen.svg');
					width: 0%;
					background-repeat: no-repeat;
					background-size: cover;
					z-index: -1;
					height: 50%;
					top: 50%;
					left: 0;
					transition: all var(--trTime2) ease;
				}
			}
		}
		&.active {
			p {
				span {
					&:after {
						width: 103%;
					}
				}
			}
		}
	}
`

export default OurVisionStyle
