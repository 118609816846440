import styled from 'styled-components'

const LookingHireStyle = styled.div`
	--secLeftWidth: 42.5%;
	--secRightWidth: 46.5%;
	--wrapPaddingTop: var(--sp23x);
	--wrapPaddingLeft: var(--sp11x);

	background-color: var(--purple);
	border-radius: var(--borderSm);
	padding: var(--wrapPaddingTop) var(--wrapPaddingLeft);

	> .Title {
		width: var(--secLeftWidth);
		z-index: 1;
	}

	.rightSection {
		width: var(--secRightWidth);
		z-index: 1;
	}

	.overflowHidden {
		position: absolute;
		top: -100%;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
		.imageCont {
			position: absolute;
			img {
				object-fit: contain !important;
			}
			&:nth-child(1) {
				padding: 9% 11%;
				bottom: -50vh;
				left: 0;
				transform: translate(-22%, 0%);
			}
			&:nth-child(2) {
				padding: 12% 15%;
				top: 150%;
				left: 50%;
				transform: translate(-45%, 0%);
			}
			&:nth-child(3) {
				padding: 11%;
				bottom: -50vh;
				right: 0;
				transform: translate(20%, 0%);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--secLeftWidth: 44%;
		--secRightWidth: 44%;
		--wrapPaddingTop: var(--sp16x);
		--wrapPaddingLeft: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--secLeftWidth: 45%;
		--secRightWidth: 48%;
		--wrapPaddingTop: var(--sp13x);
		--wrapPaddingLeft: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--secLeftWidth: 47%;
		--secRightWidth: 46.5%;
		--wrapPaddingTop: var(--sp10x);
		--wrapPaddingLeft: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--secLeftWidth: 52%;
		--secRightWidth: 42%;
		--wrapPaddingTop: var(--sp8x);
		--wrapPaddingLeft: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--secLeftWidth: 80%;
		--secRightWidth: 80%;
		--wrapPaddingTop: var(--sp12x);
		--wrapPaddingLeft: var(--sp5x);

		.overflowHidden {
			.imageCont {
				&:nth-child(1) {
					padding: 12% 15%;
				}
				&:nth-child(2) {
					padding: 15% 19%;
				}
				&:nth-child(3) {
					padding: 14%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--secLeftWidth: 90%;
		--secRightWidth: 90%;
		--wrapPaddingTop: var(--sp10x);
		--wrapPaddingLeft: var(--sp4x);

		.overflowHidden {
			.imageCont {
				&:nth-child(1) {
					padding: 17% 22%;
				}
				&:nth-child(2) {
					padding: 21% 27%;
					left: 70%;
				}
				&:nth-child(3) {
					padding: 20%;
				}
			}
		}
	}
`
export default LookingHireStyle
