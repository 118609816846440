import React, { memo as Memo } from 'react'

//* Components
import { Text, Icon, CustomLink } from 'components/common'

//* Style
import TitleSectionStyle from './style'

const TitleSection = Memo(({ title }) => {
	return (
		<TitleSectionStyle>
			<div className='backToBlock'>
				<CustomLink url={'/blog'} className='FlexBox AlignMiddle'>
					<Icon className='icon-Arrow' />
					<Text tag={'pM'} className={'pM PolySansMedianSemiBoldItalic purple relativeWrap backBtn'} text={'Back'} />
				</CustomLink>
			</div>
			<div className='subTitle'>
				<Text tag={'h4'} className={'h4 PolySansMedianRegular purple '} text={title} />
			</div>
		</TitleSectionStyle>
	)
})

export default TitleSection
