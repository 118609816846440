import React, { memo as Memo } from 'react'

//* Components
import { Container, Text, CustomLink, Translate } from 'components/common'

//* Pages
import { CollapseItem } from 'components/pages'

//* Style
import PricingCollapseStyle from './style'

// Helper
import config from 'helpers/_config'

const PricingCollapse = Memo(() => {
	const data = [
		{
			title: 'What forms of payment do you accept?',
			desc: 'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz.',
		},
		{
			title: 'What forms of payment do you accept?',
			desc: 'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz.',
		},
		{
			title: 'What forms of payment do you accept?',
			desc: 'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz.',
		},
		{
			title: 'What forms of payment do you accept?',
			desc: 'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz.',
		},
		{
			title: 'What forms of payment do you accept?',
			desc: 'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz.',
		},
	]

	return (
		<></>
		// <Container>
		// 	<PricingCollapseStyle>
		// 		<div className='FlexBoxContainer SpaceBetween'>
		// 			<div className='QAWrap'>
		// 				<div>
		// 					<Text tag={'h2'} className={'h2 PolySansNeutralRegular purple QAWrapTitle'} text={'qa'} />
		// 					<Text tag={'h6'} className={'h6 PolySansNeutralRegular purple qaDesc'} text={'QADesc'} />
		// 				</div>
		// 			</div>
		// 			<div className='CollapseWrap'>
		// 				{data?.map((el, k) => (
		// 					<CollapseItem key={k} data={el} />
		// 				))}
		// 				<div className='FlexBox AlignMiddle contactUsWrap'>
		// 					<Text tag={'h6'} className={'h6 PolySansNeutralRegular purple help'} text={'help'} />
		// 					&ensp;&ensp;
		// 					<CustomLink url={config.routes.imprint.path} className={'h6 PolySansMedianSemiBoldItalic purple underline'}>
		// 						<Translate val={'contactUs'} />
		// 					</CustomLink>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</PricingCollapseStyle>
		// </Container>
	)
})

export default PricingCollapse
