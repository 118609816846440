import styled from 'styled-components'

const OurTeamItemStyle = styled.div`
	--itemWidth: 25%;
	--itemAboutMargin: var(--sp6x) 0 var(--sp4x) 0;
	--itemNameMarginRight: var(--sp4x);
	--iconWrapSize: var(--sp5x);

	&:nth-child(1) {
		.imgWrap {
			.shape {
				animation: circleAnim 2s linear infinite;
				@keyframes circleAnim {
					0% {
						transform: translate(-10px, -10px) scale(1.1);
					}
					25% {
						transform: translate(-10px, 10px) scale(1.1);
					}
					50% {
						transform: translate(10px, 10px) scale(1.1);
					}
					75% {
						transform: translate(10px, -10px) scale(1.1);
					}
					100% {
						transform: translate(-10px, -10px) scale(1.1);
					}
				}
			}
		}
	}
	&:nth-child(2) {
		.imgWrap {
			.shape {
				animation: trapezoidAnim 3s linear infinite;

				@keyframes trapezoidAnim {
					0% {
						transform: rotate(0deg) scale(1.22);
					}
					25% {
						transform: rotate(15deg) scale(1.22);
					}
					50% {
						transform: rotate(0deg) scale(1.22);
					}
					75% {
						transform: rotate(-10deg) scale(1.22);
					}
					100% {
						transform: rotate(0deg) scale(1.22);
					}
				}
			}
		}
	}
	&:nth-child(3) {
		.shape {
			animation: ovalAnim 1.5s linear infinite;

			@keyframes ovalAnim {
				0% {
					transform: rotate(0deg) scale(1.14);
				}
				25% {
					transform: rotate(9deg) scale(1.14);
				}
				50% {
					transform: rotate(0deg) scale(1.14);
				}
				75% {
					transform: rotate(-9deg) scale(1.14);
				}
				100% {
					transform: rotate(0deg) scale(1.14);
				}
			}
		}
	}

	width: var(--itemWidth);

	.itemAbout {
		position: relative;
		z-index: 1;
		margin: var(--itemAboutMargin);
		.fullName {
			margin-right: var(--itemNameMarginRight);
		}
	}
	.imgWrap {
		position: relative;
		width: 100%;
		.imageCont {
			padding-top: 100%;
		}
	}
	.shape {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 1;
	}

	.iconWrap {
		display: inline-flex;
		width: var(--iconWrapSize);
		height: var(--iconWrapSize);
		background: var(--purpleLite);
		border-radius: 10%;
		position: relative;
		z-index: 2;

		i {
			&::before {
				font-size: var(--sp4x);
				color: var(--white);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--itemAboutMargin: var(--sp5x) 0 var(--sp3x) 0;
		--itemNameMarginRight: var(--sp3x);
		--iconWrapSize: var(--sp4x);

		.iconWrap {
			i {
				&::before {
					font-size: var(--sp3x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--itemAboutMargin: var(--sp5x) 0 var(--sp3x) 0;
		--itemNameMarginRight: var(--sp3x);
		--iconWrapSize: var(--sp4x);

		.iconWrap {
			i {
				&::before {
					font-size: var(--sp3x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--itemAboutMargin: var(--sp4x) 0 var(--sp2x) 0;
		--itemNameMarginRight: var(--sp2-5x);
		--iconWrapSize: var(--sp3-5x);

		&:nth-child(1) {
			.imgWrap {
				.shape {
					animation: circleAnim 3.5s linear infinite;
					@keyframes circleAnim {
						0% {
							transform: translate(-10px, -10px) scale(1.1);
						}
						25% {
							transform: translate(-10px, 10px) scale(1.1);
						}
						50% {
							transform: translate(10px, 10px) scale(1.1);
						}
						75% {
							transform: translate(10px, -10px) scale(1.1);
						}
						100% {
							transform: translate(-10px, -10px) scale(1.1);
						}
					}
				}
			}
		}

		.iconWrap {
			i {
				&::before {
					font-size: var(--sp2-5x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--itemAboutMargin: var(--sp3x) 0 var(--sp2x) 0;
		--itemNameMarginRight: var(--sp2-5x);
		--iconWrapSize: var(--sp3x);

		&:nth-child(1) {
			.imgWrap {
				.shape {
					animation: circleAnim 4s linear infinite;
					@keyframes circleAnim {
						0% {
							transform: translate(-10px, -10px) scale(1.1);
						}
						25% {
							transform: translate(-10px, 10px) scale(1.1);
						}
						50% {
							transform: translate(10px, 10px) scale(1.1);
						}
						75% {
							transform: translate(10px, -10px) scale(1.1);
						}
						100% {
							transform: translate(-10px, -10px) scale(1.1);
						}
					}
				}
			}
		}

		.iconWrap {
			i {
				&::before {
					font-size: var(--sp2x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--itemWidth: 27%;
		--itemNameMarginRight: 0;
		--itemAboutMargin: var(--sp3x) 0 var(--sp2x) 0;
		--iconWrapSize: var(--sp3x);

		.iconWrap {
			i {
				&::before {
					font-size: var(--sp2x);
				}
			}
		}

		.itemAbout {
			flex-direction: column;
			align-items: flex-start;
		}
		&:nth-child(1) {
			.imgWrap {
				.shape {
					animation: circleAnim 4s linear infinite;
					@keyframes circleAnim {
						0% {
							transform: translate(-8px, -8px) scale(1.2);
						}
						25% {
							transform: translate(-8px, 8px) scale(1.2);
						}
						50% {
							transform: translate(8px, 8px) scale(1.2);
						}
						75% {
							transform: translate(8px, -8px) scale(1.2);
						}
						100% {
							transform: translate(-8px, -8px) scale(1.2);
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--itemWidth: 62%;
		--itemAboutMargin: var(--sp3x) 0 var(--sp2x) 0;
		--itemNameMarginRight: var(--sp2x);

		--iconWrapSize: var(--sp3x);

		.iconWrap {
			i {
				&::before {
					font-size: var(--sp2x);
				}
			}
		}

		margin-top: var(--sp9x);
		&:first-child {
			margin-top: 0;
		}
		&:nth-child(1) {
			.imgWrap {
				.shape {
					animation: circleAnim 3s linear infinite;
					@keyframes circleAnim {
						0% {
							transform: translate(-8px, -8px) scale(1.2);
						}
						25% {
							transform: translate(-8px, 8px) scale(1.2);
						}
						50% {
							transform: translate(8px, 8px) scale(1.2);
						}
						75% {
							transform: translate(8px, -8px) scale(1.2);
						}
						100% {
							transform: translate(-8px, -8px) scale(1.2);
						}
					}
				}
			}
		}
	}
`

export default OurTeamItemStyle
