import styled from 'styled-components'

const HeroStyle = styled.div`
	--titleMargBottom: var(--sp22x);
	--LeftSectionWidth: 52%;
	--RightSectionWidth: calc(100% - var(--LeftSectionWidth));

	.LeftHomeSection {
		width: var(--LeftSectionWidth);
		.titleHome {
			h1:nth-child(2) {
				margin-left: var(--titleMargBottom);
			}
		}
		.descriptionWrap {
			.description {
				width: 80%;
				&:last-child {
					opacity: 0;
					visibility: hidden;
					pointer-events: none;
				}
				&:first-child {
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}

	.RightHomeSection {
		width: var(--RightSectionWidth);
		.LottieWrapp {
			margin-top: -37%;
			padding-top: 137%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--titleMargBottom: var(--sp13x);
		--LeftSectionWidth: 50%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--titleMargBottom: var(--sp13x);
		--LeftSectionWidth: 51%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMargBottom: var(--sp12x);
		--LeftSectionWidth: 49%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--titleMargBottom: var(--sp10x);

		.RightHomeSection {
			.LottieWrapp {
				margin-top: -40%;
				padding-top: 176%;
				margin-left: -28%;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--titleMargBottom: var(--sp10x);
		--LeftSectionWidth: 60%;
		--RightSectionWidth: 77%;

		.RightHomeSection {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			.LottieWrapp {
				margin-top: -35%;
				padding-top: 110%;
				width: var(--RightSectionWidth);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMargBottom: var(--sp6x);
		--LeftSectionWidth: 100%;
		--RightSectionWidth: 100%;

		.RightHomeSection {
			.LottieWrapp {
				margin-top: 0px;
				padding-top: 130%;
			}
		}
	}
`
export default HeroStyle
