import styled from 'styled-components'

const HeroStyle = styled.div`
	--LeftSectionWidth: 56.8%;
	--RightSectionWidth: calc(100% - var(--LeftSectionWidth));
	--descriptionMargin: var(--sp5x) 0 var(--sp13x) 0;
	--descriptionWidth: 58%;

	.LeftAboutSection {
		width: var(--LeftSectionWidth);

		.description {
			margin: var(--descriptionMargin);
			width: var(--descriptionWidth);
		}
	}

	.RightAboutSection {
		width: var(--RightSectionWidth);
		.LottieWrapp {
			margin-top: -35%;
			padding-top: 140%;
			transform: translateY(10%);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--descriptionMargin: var(--sp4x) 0 var(--sp9x) 0;
		--LeftSectionWidth: 57.4%;

		.RightAboutSection {
			.LottieWrapp {
				margin-top: -35%;
				padding-top: 140%;
				transform: translateY(10%);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--descriptionMargin: var(--sp3x) 0 var(--sp8x) 0;
		--LeftSectionWidth: 59%;
		--descriptionWidth: 75%;

		.RightAboutSection {
			.LottieWrapp {
				margin-top: -40%;
				padding-top: 150%;
				transform: translateY(18%);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--descriptionMargin: var(--sp3x) 0 var(--sp7x) 0;
		--LeftSectionWidth: 59.3%;
		--descriptionWidth: 65%;
		.RightAboutSection {
			.LottieWrapp {
				margin-top: -45%;
				padding-top: 138%;
				transform: translateY(20%);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--descriptionMargin: var(--sp3x) 0 var(--sp6x) 0;
		--LeftSectionWidth: 61.4%;
		--descriptionWidth: 75%;

		.RightAboutSection {
			.LottieWrapp {
				padding-top: 180%;
				margin-left: -30%;
				margin-top: -65%;
				transform: translateY(20%);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--descriptionMargin: var(--sp3x) 0 var(--sp6x) 0;
		--descriptionWidth: 68%;
		--LeftSectionWidth: 76.7%;
		--RightSectionWidth: 77%;

		.RightAboutSection {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			.LottieWrapp {
				margin-top: -30%;
				padding-top: 105%;
				width: var(--RightSectionWidth);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--descriptionMargin: var(--sp2x) 0 var(--sp5x) 0;
		--LeftSectionWidth: 100%;
		--RightSectionWidth: 100%;
		--descriptionWidth: 100%;

		.RightAboutSection {
			.LottieWrapp {
				margin-top: -15%;
				padding-top: 150%;
			}
		}
	}
`
export default HeroStyle
