import styled from 'styled-components'

const OurTeamStyle = styled.div`
	--itemWrap: var(--sp13x);

	.itemWrap {
		justify-content: flex-start;
		margin-top: var(--itemWrap);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--itemWrap: var(--sp9x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--itemWrap: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--itemWrap: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
		--itemWrap: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--itemWrap: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--itemWrap: var(--sp4x);
		.itemWrap {
			flex-direction: column;
			align-items: center;
		}
	}
`

export default OurTeamStyle
