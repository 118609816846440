import React from 'react'
// Style
import OurTeamItemStyle from './style'
// Components
import { Image, Text, Icon } from 'components/common'

const OurTeamItem = ({ shape, src, fullName, position, url }) => {
    return (
        <OurTeamItemStyle>
            <div className='imgWrap'>
                <Image src={src} className='image' />
                <Image src={shape} className='shape' />
            </div>
            <div className='FlexBox itemAbout AlignMiddle'>
                <Text tag={'h6'} text={fullName} className={'h6 PolySansMedianRegular purple fullName'} />
                <Text tag={'p'} text={position} className={'pM PolySansNeutralRegular purple'} />
            </div>
            <a href={url} target='_blank' aria-label='linkedin'>
                <div className='iconWrap AlignMiddle AlignCenter'>
                    <Icon className='icon-linkedin' />
                </div>
            </a>
        </OurTeamItemStyle>
    )
}

export default OurTeamItem
