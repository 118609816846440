import React from 'react'
// Style
import OurTeamStyle from './style'
// Components
import { Container, Title } from 'components/common'
import { OurTeamItem } from 'components/pages'

const OurTeam = ({ data }) => {
    return (
        <OurTeamStyle>
            <Container>
                <Title text={['Our', 'team']} fontSize={'h2'} />
                <div className='FlexBoxContainer itemWrap'>

                    {data.map((i, k) => {
                        return (
                            <OurTeamItem
                                url={i.url}
                                key={k}
                                src={i.src}
                                fullName={i.fullName}
                                position={i.position}
                                shape={i.shape}
                            />
                        )
                    })}
                </div>
            </Container>
        </OurTeamStyle>
    )
}

export default OurTeam
