import React, { memo as Memo, useCallback, useEffect, useRef } from 'react'

import { gsap } from 'gsap'
import { ScrambleTextPlugin } from 'gsap/dist/ScrambleTextPlugin'
import { SplitText } from 'gsap/dist/SplitText'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { Button, Container, Title, Text, LottieAnimation, RequestPopup } from 'components/common'

import HeroStyle from './style'
import homeLottie from 'lottieAnimation/home/homePageHeroSectionLottie.json'

gsap.registerPlugin(ScrambleTextPlugin, SplitText)

const HeroSection = Memo(({ data, preloader, openPopup, ...props }) => {
	const scrambleRef1 = useRef()
	const scrambleRef2 = useRef()
	const scrambleRef3 = useRef()
	const scrambleRef4 = useRef()

	const tl = gsap.timeline({ defaults: { duration: 2, ease: 'none' } })

	const openPopupFun = useCallback(() => {
		openPopup(<RequestPopup />)
	}, [])

	useEffect(() => {
		const mySplitText1 = new SplitText(scrambleRef1.current, { type: 'chars' })
		const mySplitText2 = new SplitText(scrambleRef2.current, { type: 'chars' })
		const mySplitText3 = new SplitText(scrambleRef3.current, { type: 'chars' })

		const mySplitText4 = new SplitText(scrambleRef4.current, { type: 'words,chars' })

		const chars = [mySplitText1.chars, mySplitText2.chars, mySplitText3.chars, mySplitText4.chars]

		if (!preloader) {
			for (let k = 0; k < chars.length; k++) {
				for (let i = 0; i < chars[k].length; i++) {
					tl.to(
						chars[k][i],
						0.5,
						{ scrambleText: { text: chars[k][i].textContent, chars: 'abcdefghijklmnopqrstuvwxyz!^<>+=-)(*&$%#@~|?' } },
						0.01 * i
					)
				}
			}
		}
	}, [preloader])

	return data ? (
		<Container className='containerPaddBottom containerPaddTop'>
			<HeroStyle className='FlexBoxContainer '>
				<div className='LeftHomeSection'>
					<div className='titleHome titleMaxMargBot'>
						<Text ref={scrambleRef1} tag={'h1'} className={'title PolySansBulkyRegular purple'} text={'theSoftware'} />

						<Text ref={scrambleRef2} tag={'h1'} className={'title PolySansBulkyRegular purple'} text={'development'} />

						<Text ref={scrambleRef3} tag={'h1'} className={'title PolySansNeutralItalic purple'} text={'marketplace'} />
					</div>
					<div className='relativeWrap descriptionWrap'>
						<Text ref={scrambleRef4} className={'description pL PolySansNeutralRegular purple'} text={data.text} />
						<Text className={'description pL PolySansNeutralRegular purple'} text={data.text} />
					</div>

					<Button onClick={openPopupFun} className={'purple'} wrapClassName={'btnContTopMarg'}>
						<Title type={'btn'} text={['requestAn', 'onboarding']} fontSize={'pM'} className={'whiteDark'} />
					</Button>
				</div>
				<div className='RightHomeSection'>
					<LottieAnimation animData={homeLottie} isPlay={!props.preloader} autoplay={false} loop={true} />
				</div>
			</HeroStyle>
		</Container>
	) : (
		''
	)
})

export default withLanguageContext(withUIContext(HeroSection, ['winWidth', 'screenSizes', 'preloader', 'openPopup']), ['selectedLang'])
