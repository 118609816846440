import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import queryString from 'query-string'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'

//! Components
import { Text } from 'components/common'

//! Style
import PaginationStyle from './style'

const Pagination = ({ total, pageSize, lastPage }) => {
	const router = useRouter()
	const [hasRouter, setHasRouter] = useState(false)
	useEffect(() => {
		setHasRouter(true)
	}, [router])

	//Router
	if (!hasRouter) return null
	const query = pickBy({ ...(router.query || {}) }, (q) => !isEmpty(q))

	const currentPage = Number(query.page || 1)
	const isLastPage = currentPage * pageSize >= total

	const url = (page) => `?${queryString.stringify({ ...query, page })}`

	return (
		<PaginationStyle>
			<div className='hrBackCol'>
				<hr />
			</div>
			<div className='FlexBox SpaceBetween AlignMiddle'>
				<div className='FlexBox JustifyStart PagItem'>
					<div>
						{currentPage !== 1 ? (
							<NextLink href={url(currentPage - 1)} passHref prefetch={false}>
								<Text
									tag={'h6'}
									text={'PREV'}
									className={'btnNext h6 purple PolySansMedianSemiBoldItalic cursorPointer relativeWrap'}
								/>
							</NextLink>
						) : null}
					</div>
				</div>
				<div className='FlexBox AlignCenter PagItem'>
					<div className='FlexBox'>
						<Text tag={'h6'} text={currentPage} className={'h6 purpleLite PolySansMedianRegular '} />
						<Text tag={'h6'} text={`/${lastPage}`} className='h6 purple PolySansMedianRegular' />
					</div>
				</div>
				<div className='FlexBox JustifyEnd PagItem'>
					<div>
						{!isLastPage ? (
							<NextLink href={url(currentPage + 1)} passHref prefetch={false}>
								<Text
									tag={'h6'}
									text={'NEXT'}
									className={' btnNext h6 purple PolySansMedianSemiBoldItalic cursorPointer relativeWrap'}
								/>
							</NextLink>
						) : null}
					</div>
				</div>
			</div>
		</PaginationStyle>
	)
}

export default Pagination
