import React, { memo as Memo, useRef, useCallback, useEffect, useState } from 'react'

import { withUIContext } from 'context'

//* GSAP
import { gsap } from 'gsap'

//* Components
import { Container, Title, Image, Text, AnimationWrapp } from 'components/common'

//* Style
import PricingAnimationStyle from './style'

//* JSON
import data from './animData.json'

const PricingAnimation = Memo(({ ...props }) => {
	//! States
	const [secObj, setSecObj] = useState()
	const [secStart, setSecStart] = useState(false)

	//! Refs
	const textRef = useRef()
	const CursorRef = useRef()

	const mouseOver = useCallback(
		(e) => {
			if (props.winWidth >= 1024 && e.clientX) {
				gsap.to(CursorRef.current, { rotation: (props.winWidth - e.clientX) / 30 })
			}
		},
		[props.winWidth]
	)

	useEffect(() => {
		window.addEventListener('mousemove', mouseOver)
		return () => window.removeEventListener('mousemove', mouseOver)
	})

	useEffect(() => {
		if (textRef?.current) {
			setSecObj([textRef.current])
		}
	}, [textRef, props.selectedLang])

	const startAnimeArr = useCallback((x) => {
		setSecStart(x)
	}, [])

	return data ? (
		<Container>
			<AnimationWrapp state={secObj} startAnimeArr={startAnimeArr}>
				<PricingAnimationStyle>
					<div className='thirdSection relativeWrap'>
						<div className='sectionWrap FlexBoxContainer'>
							<div className={`secWrapInfo ${secStart ? 'active' : ''} `}>
								<Title type={'btn'} text={['earlybirdsPro', 'forFree']} fontSize={'h2'} className={'purple'} />
								<div ref={textRef} dangerouslySetInnerHTML={{ __html: data.desc }} className='secondDesc'></div>
							</div>
							<div className='secWrapAnime relativeWrap ' ref={CursorRef}>
								<div>
									<div className='absoluteWrap'>
										<Image src={'/images/svg/magnet.svg'} alt='magnet' />
									</div>
									<div className='protoTypeText'>
										<div>
											<Text tag={'span'} className={'h4 PolySansNeutralItalic whiteDark'} text={data.numberItalic} />
											<Text
												tag={'span'}
												className={'h4 PolySansMedianSemiBoldItalic whiteDark'}
												text={`/${data.numberSemiBold}`}
											/>
										</div>
										<Title
											type={'btn'}
											text={[data.textNeutral, `- ${data.textItalic}`]}
											fontSize={'pL'}
											className={'whiteDark'}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</PricingAnimationStyle>
			</AnimationWrapp>
		</Container>
	) : (
		''
	)
})

export default withUIContext(PricingAnimation, ['winWidth'])
