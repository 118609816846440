import React, { memo as Memo, useEffect, useRef, useCallback, useState } from 'react'

import { withLanguageContext } from 'context'

//* Components
import { Container, Text, Translate, AnimationWrapp } from 'components/common'

import TopCategoriesStyle from './style'

const TopCategoriesSection = Memo(({ data, preloader, ...props }) => {
	//! States
	const [secObj, setSecObj] = useState()
	const [secStart, setSecStart] = useState(false)

	//! Refs
	const items = useRef()

	useEffect(() => {
		if (data && items?.current) {
			setSecObj([items.current])
		}
	}, [items, data, props.selectedLang])

	const startAnimeArr = useCallback((x) => {
		setSecStart(x)
	}, [])

	return data ? (
		<Container className='containerPaddBottom '>
			<TopCategoriesStyle>
				<Text tag={'h3'} className={'titleMaxMargBot PolySansMedianSemiBoldItalic purple'} text={'weprofitTopCategoriesAre'} />
				<AnimationWrapp state={secObj} startAnimeArr={startAnimeArr}>
					<div className={`ItemsWrap ${secStart ? 'active' : ''}`} ref={items}>
						{data.map((item, ind) => {
							return (
								<span key={ind}>
									<Text tag={'span'} className={'relativeWrap h3 PolySansNeutralRegular purple'}>
										<Translate val={item} />
										<svg width='100%' height='100%' viewBox='0 0 1235 27.219' preserveAspectRatio='none'>
											<path
												d='M1415.974,421.269c-5.293-.1-10.591-.2-15.837-.287-97.126-1.323-194.233-.452-302.136-1.191-75.539-.846-161.845-.011-237.356.24,179,.417,367.122-.591,539.133,2.636-17.375,1.353-38.14,2.456-62.328,2.536q-87.31-.477-172.622-.54c0-.554-.014-1.635-.019-2.179-161.828,2.184-312.934-1.7-474.752,1.578,5.757.273,2.348.732,1.157,1.09l-1.148.007,1.148-.007c-1.075.315-.412.554,9.657.521-10.069.033-10.733-.206-9.657-.521,159.213-1.063,308.093-1.029,467.1-.494-246.891-.206-484.913,1.653-737.978,2.495L377.2,427.3c-43.172-1.5-86.354-1.908-129.484-1.766,10.748-2.779-32.457-8.122,53.887-7.312,0-.547-.015-1.645-.018-2.194-86.358-2.457-118.8-10.031-32.483-12.513,97.076-2.517,226.577-.754,345.232-2.245,86.331-.835,172.649-1.671,258.947-.86,118.7,1.251,248.174.272,366.857,2.072,43.181.406,118.7-1.491,140.3.083,15.508.736,16.132,1.9,13.772,3.213-70.942.043-141.665.218-211.6.211q-128.693-.494-257.345-1.534c83.865,1.227,169.983,1.524,257.345,1.534q104.663.4,209.387.849c-2.544,1.128-4.987,2.3-.722,3.373l32.359-.107c43.2,2.6,32.415,6.477,10.9,9.292C1429.03,419.969,1422.859,420.617,1415.974,421.269Z'
												transform='translate(-218.348 -400.078)'
												fill='#02db9c'
											/>
										</svg>
									</Text>
									{ind !== data.length - 1 ? <span className='h3 PolySansBulkyRegular purpleLite'> &nbsp;/&nbsp;</span> : ''}
								</span>
							)
						})}
					</div>
				</AnimationWrapp>
			</TopCategoriesStyle>
		</Container>
	) : (
		''
	)
})

export default withLanguageContext(TopCategoriesSection, ['selectedLang'])
