import React, { memo as Memo, useState, useRef, useEffect, useCallback } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

// Style
import OurVisionStyle from './style'
// Components
import { Container, Title, AnimationWrapp } from 'components/common'

const OurVision = Memo(({ data, selectedLang }) => {
	//! States
	const [secObj, setSecObj] = useState()
	const [startAnim, setStartAnim] = useState(false)

	//! Refs
	const items = useRef([])

	useEffect(() => {
		if (items?.current) {
			setSecObj([items.current])
		}
	}, [items, selectedLang])

	const startAnimeArr = useCallback(
		(x) => {
			setStartAnim(x)
		},
		[startAnim]
	)

	return (
		<OurVisionStyle>
			<AnimationWrapp state={secObj} startAnimeArr={startAnimeArr}>
				<Container>
					<Title text={['Our', 'vision']} className={'h1 purple'} />
					<div className={`danger description ${startAnim ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: data.text }} ref={items} />
				</Container>
			</AnimationWrapp>
		</OurVisionStyle>
	)
})
export default withLanguageContext(OurVision, ['selectedLang'])
