import React, { memo as Memo, useState, useRef, useEffect, useMemo, useCallback } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Container, Title, Text, LottieAnimation, AnimationWrapp } from 'components/common'

import LoveWeprofitStyle from './style'

import quoality from 'lottieAnimation/home/quoality.json'
import speed from 'lottieAnimation/home/speed.json'
import security from 'lottieAnimation/home/security.json'
import digital from 'lottieAnimation/home/digital.json'

const LoveWeprofitSection = Memo(({ data, selectedLang, ...props }) => {
	const Lottie = [quoality, speed, security, digital]

	//! States
	const [secObj, setSecObj] = useState()
	const [lottieObj, setLottieObj] = useState([])
	const [identificator, setIdentificator] = useState(0)

	//! Refs
	const items = useRef([])

	useEffect(() => {
		if (data && items?.current.length) {
			setSecObj(items?.current)
		}
	}, [items, data, selectedLang])

	const startAnimeArr = useCallback(
		(x) => {
			setLottieObj(x)
			setIdentificator(x.length)
		},
		[identificator]
	)

	const itemsLottie = useMemo(() => {
		if (data.length) {
			return data.map((item, ind) => {
				return (
					<div className='items' key={ind} ref={(el) => (items.current[ind] = el)}>
						<LottieAnimation animData={Lottie[ind]} isPlay={lottieObj[ind]} autoplay={false} loop={true} className='titleMaxMargBot' />
						<Text className={'titleMinMargBot h6 PolySansNeutralItalic purple'}>{item.title}</Text>
						<Text className={'pM PolySansNeutralRegular purple'} text={item.text} />
					</div>
				)
			})
		}
	}, [data, lottieObj, identificator])

	return data ? (
		<Container className='containerPaddBottom '>
			<AnimationWrapp state={secObj} startAnimeArr={startAnimeArr}>
				<LoveWeprofitStyle>
					<Title className={'titleWrap purple'} text={['whyPeopleLove', 'weprofit']} />

					<div className='ItemsWrap FlexBoxContainer SpaceBetween'>{itemsLottie}</div>
				</LoveWeprofitStyle>
			</AnimationWrapp>
		</Container>
	) : (
		''
	)
})

export default withLanguageContext(LoveWeprofitSection, ['selectedLang'])
