import styled from 'styled-components'
const InfoSectionStyle = styled.div`
	--infoSectionPadRight: var(--sp26-5x);
	--infoSectionItemMargBot: var(--sp12x);
	--infoSectionTitleMargBot: var(--sp4x);
	--olMargTop: var(--sp6x);
	--olLiMargBot: var(--sp4x);
	--imgMargTop: var(--sp12x);

	width: var(--descWidth);
	padding-right: var(--infoSectionPadRight);

	.contentDescription {
		& h1:first-child {
			margin-top: 0;
		}

		& h2:first-child {
			margin-top: 0;
		}

		& h3:first-child {
			margin-top: 0;
		}

		& h4:first-child {
			margin-top: 0;
		}

		h1,
		h2,
		h3,
		h4 {
			font-size: var(--h6);
			font-family: PolySansMedianRegular;
			color: var(--purple);
			margin-bottom: var(--infoSectionTitleMargBot);
			margin-top: var(--infoSectionItemMargBot);
		}

		p {
			font-size: var(--pM);
			font-family: PolySansNeutralRegular;
			color: var(--purple);
			margin-bottom: var(--sp2x);
		}

		& p:nth-child(2n) {
			margin-bottom: 0;
		}

		strong {
			font-weight: normal;
		}

		figure {
			position: relative;
			padding-top: 62%;
			margin-block-start: 0;
			margin-block-end: 0;
			margin-inline-start: 0;
			margin-inline-end: 0;
			margin-top: var(--imgMargTop);
			margin-bottom: var(--sp3x);

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				object-position: left;
			}
		}

		ol, ul {
			margin-block-start: 0;
			margin-block-end: 0;
			padding-inline-start: 0;
			margin-top: var(--olMargTop);
			counter-reset: item;
			list-style-type: none;

			li {
				font-size: var(--pM);
				font-family: PolySansNeutralRegular;
				color: var(--purple);
				margin-bottom: var(--olLiMargBot);

				&:before {
					content: counter(item) '/';
					counter-increment: item;
					color: var(--purpleLite);
					margin-right: var(--spSize);
				}
			}

			& li:last-child {
				margin-bottom: 0;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--infoSectionPadRight: var(--sp20x);
		--infoSectionTitleMargBot: var(--sp3x);
		--infoSectionItemMargBot: var(--sp9x);
		--imgMargTop: var(--sp9x);
		--olMargTop: var(--sp5x);
		--olLiMargBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--infoSectionPadRight: var(--sp18x);
		--infoSectionTitleMargBot: var(--sp3x);
		--infoSectionItemMargBot: var(--sp8x);
		--imgMargTop: var(--sp8x);
		--olMargTop: var(--sp5x);
		--olLiMargBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--infoSectionPadRight: var(--sp16-5x);
		--infoSectionTitleMargBot: var(--sp3x);
		--infoSectionItemMargBot: var(--sp7x);
		--imgMargTop: var(--sp7x);
		--olMargTop: var(--sp4x);
		--olLiMargBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--infoSectionPadRight: 0;
		--infoSectionTitleMargBot: var(--sp3x);
		--infoSectionItemMargBot: var(--sp6x);
		--imgMargTop: var(--sp6x);
		--olMargTop: var(--sp4x);
		--olLiMargBot: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--infoSectionPadRight: 0;
		--infoSectionTitleMargBot: var(--sp3x);
		--infoSectionItemMargBot: var(--sp6x);
		--imgMargTop: var(--sp6x);
		--olMargTop: var(--sp4x);
		--olLiMargBot: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--infoSectionPadRight: 0;
		--infoSectionTitleMargBot: var(--sp2x);
		--infoSectionItemMargBot: var(--sp6x);
		--imgMargTop: var(--sp6x);
		--olMargTop: var(--sp4x);
		--olLiMargBot: var(--sp2x);
	}
`
export default InfoSectionStyle
