import React, { memo as Memo, useEffect, useCallback, useRef, useState } from 'react'

//* HOCs
import { withUIContext } from 'context'

//* Helpers
import { gsap } from 'gsap'

//* Components
import { Container, Title, Text, Button, Image, RequestPopup } from 'components/common'

//* Style
import LookingHireStyle from './style'

const LookingHireSection = Memo(({ data, preloader, openPopup, ...props }) => {
	const [start, setStart] = useState(true)

	const contRefTop = useRef()
	const contRefBottom = useRef()
	const itemCircle = useRef()
	const itemOval = useRef()
	const itemHalf = useRef()

	const openPopupFun = useCallback(() => {
		openPopup(<RequestPopup />)
	}, [])

	const sectionAnimeTop = useCallback(() => {
		const elTop = contRefTop?.current?.getBoundingClientRect()
		const pointStart = window.innerHeight

		if (elTop.top + 200 < pointStart && start) {
			gsap.to(itemHalf.current, 0.9, { bottom: '-7%' })
			gsap.to(itemOval.current, 0.9, { top: props.winWidth >= 1280 ? '43%' : '47%', delay: 0.2 })
			gsap.to(itemCircle.current, 0.9, { bottom: '-12%', delay: 0.3 })
			setTimeout(() => {
				setStart(false)
			}, 1000)
		}
	}, [start, itemHalf, itemOval, itemCircle, props.winWidth])

	const sectionAnimeBottom = useCallback(() => {
		const elTop = contRefBottom?.current?.getBoundingClientRect()
		const pointStart = window.innerHeight

		if (elTop?.top < pointStart && !start) {
			if (elTop.top + window.innerHeight > 0 && elTop.top > 0) {
				gsap.to(itemHalf.current, 0.5, { y: `${(elTop.top - pointStart) / 4}` })
				gsap.to(itemOval.current, 0.9, { y: `${(elTop.top - pointStart) / 3}` })
				gsap.to(itemCircle.current, 0.3, { y: `${(elTop.top - pointStart) / 2}` })
			} else {
				gsap.to(itemHalf.current, 0.5, { y: `-${(elTop.top - pointStart) / 4}` })
				gsap.to(itemOval.current, 0.9, { y: `-${(elTop.top - pointStart) / 2}` })
				gsap.to(itemCircle.current, 0.3, { y: `-${(elTop.top - pointStart) / 5}` })
			}
		}
	}, [start, itemHalf, itemOval, itemCircle])

	useEffect(() => {
		window.addEventListener('scroll', sectionAnimeTop)
		window.addEventListener('scroll', sectionAnimeBottom)

		return () => {
			window.removeEventListener('scroll', sectionAnimeTop)
			window.removeEventListener('scroll', sectionAnimeBottom)
		}
	}, [sectionAnimeTop, sectionAnimeBottom])

	return data ? (
		<Container className='containerPaddBottom '>
			<LookingHireStyle className='FlexBoxContainer SpaceBetween relativeWrap' ref={contRefTop}>
				<Title className={'titleWrap titleMaxMargBot whiteDark'} text={['lookingToHire', 'hire', 'orTo', 'hired']} />

				<div className='rightSection'>
					<Text className={'h6 PolySansNeutralRegular whiteDark'} text={data} />

					<Button onClick={openPopupFun} className={'white'} wrapClassName={'btnContTopMarg'}>
						<Title type={'btn'} text={['requestAn', 'onboarding']} fontSize={'pM'} />
					</Button>
				</div>
				<div className='overflowHidden'>
					<Image src={'images/home/figure/ellipse.svg'} ref={itemHalf} alt='ellipse_image' />
					<Image src={'images/home/figure/oval.svg'} ref={itemOval} alt='oval_image' />
					<Image src={'images/home/figure/circle.svg'} ref={itemCircle} alt='circle_image' />
				</div>
			</LookingHireStyle>
			<div ref={contRefBottom}></div>
		</Container>
	) : (
		''
	)
})

export default withUIContext(LookingHireSection, ['winWidth', 'screenSizes', 'openPopup'])
