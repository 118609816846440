import { useMemo } from 'react'

//* Components
import { Text } from 'components/common'

//* Style
import BlogFilterStyle from './style'

const BlogFilter = ({ categories, onCategoryChange, router, queryParamKeys }) => {
	const activeFilter = useMemo(() => router.query?.[queryParamKeys[0]], [router.query])

	const renderFilter = useMemo(() => {
		return (
			<div className='filterListPadRight FlexBox'>
				<div className={`filterItem cursorPointer ${!activeFilter ? 'active' : ''}`} onClick={() => onCategoryChange('catId', '')}>
					<Text tag={'pS'} className='pS PolySansNeutralRegular purple' text={'All'} />
				</div>
				{categories?.map((cat) => (
					<div
						className={`filterItem cursorPointer ${activeFilter == cat.id ? 'active' : ''}`}
						key={cat.id}
						onClick={() => onCategoryChange('catId', cat.id)}
					>
						<Text tag={'pS'} className='pS PolySansNeutralRegular purple' text={cat.name} />
					</div>
				))}
			</div>
		)
	}, [categories, activeFilter])

	return (
		<BlogFilterStyle className='FlexBox JustifyEnd'>
			<div className='FlexBox mobileStyle'>{renderFilter}</div>
		</BlogFilterStyle>
	)
}

export default BlogFilter
