import styled from 'styled-components'

const IndividualHeroSectionStyle = styled.div`
	--heroSectionPadLeft: var(--sp26x);
	--heroSectionPadRight: var(--sp26-5x);
	--catNameMargBot: var(--sp4x);
	--personNameMargBot: var(--sp2x);
	--personOccupationMargBot: var(--sp7x);
	--dateMargBot: var(--sp9x);
	--iconFontSize: var(--sp5-5x);
	--shareNameMargBot: var(--sp3x);
	--socialItemMargRight: var(--sp3x);
	--sectionMargBot: var(--sp22x);

	padding-left: var(--heroSectionPadLeft);
	padding-right: var(--heroSectionPadRight);
	margin-bottom: var(--sectionMargBot);

	.heroInfo {
		width: 40%;
		.catName {
			margin-bottom: var(--catNameMargBot);
		}

		.personName {
			margin-bottom: var(--personNameMargBot);
		}

		.personOccupation {
			margin-bottom: var(--personOccupationMargBot);
		}

		.date {
			margin-bottom: var(--dateMargBot);
			p:first-child {
				&:after {
					content: '';
					border-right: 1px solid var(--purple);
					margin: 0 7px;
				}
			}
		}

		.shareName {
			margin-bottom: var(--shareNameMargBot);
		}

		.socIcon {
			a {
				i {
					font-size: var(--iconFontSize);
					color: var(--white);
					background-color: var(--purple);
					border-radius: 4px;
				}

				&:not(:last-child) {
					margin-right: var(--socialItemMargRight);
				}
			}
		}
	}

	.heroImage {
		width: 60%;
		.imageCont {
			padding-top: 60.2%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--heroSectionPadLeft: var(--sp19-5x);
		--heroSectionPadRight: var(--sp19-5x);
		--catNameMargBot: var(--sp3x);
		--personNameMargBot: var(--sp1x);
		--personOccupationMargBot: var(--sp6x);
		--dateMargBot: var(--sp8x);
		--iconFontSize: var(--sp4-5x);
		--shareNameMargBot: var(--sp2x);
		--socialItemMargRight: var(--sp2x);
		--sectionMargBot: var(--sp16x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--heroSectionPadLeft: var(--sp14x);
		--heroSectionPadRight: var(--sp14-5x);
		--catNameMargBot: var(--sp2x);
		--personNameMargBot: var(--sp1x);
		--personOccupationMargBot: var(--sp5x);
		--dateMargBot: var(--sp7x);
		--shareNameMargBot: var(--sp2x);
		--iconFontSize: var(--sp4-5x);
		--socialItemMargRight: var(--sp2x);
		--sectionMargBot: var(--sp13x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--heroSectionPadLeft: var(--sp10x);
		--heroSectionPadRight: var(--sp10x);
		--catNameMargBot: var(--sp2x);
		--personNameMargBot: var(--sp1x);
		--personOccupationMargBot: var(--sp5x);
		--dateMargBot: var(--sp7x);
		--shareNameMargBot: var(--sp2x);
		--iconFontSize: var(--sp4-5x);
		--socialItemMargRight: var(--sp2x);
		--sectionMargBot: var(--sp12x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--heroSectionPadLeft: var(--sp9x);
		--heroSectionPadRight: var(--sp9x);
		--catNameMargBot: var(--sp2x);
		--personNameMargBot: var(--sp1x);
		--personOccupationMargBot: var(--sp5x);
		--dateMargBot: var(--sp6x);
		--shareNameMargBot: var(--sp2x);
		--iconFontSize: var(--sp4-5x);
		--socialItemMargRight: var(--sp2x);
		--sectionMargBot: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--heroSectionPadLeft: var(--sp4-5x);
		--heroSectionPadRight: var(--sp4-5x);
		--catNameMargBot: var(--sp2x);
		--personNameMargBot: var(--sp1x);
		--personOccupationMargBot: var(--sp5x);
		--dateMargBot: var(--sp6x);
		--shareNameMargBot: var(--sp2x);
		--iconFontSize: var(--sp4-5x);
		--socialItemMargRight: var(--sp2x);
		--sectionMargBot: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--heroSectionPadLeft: var(--sp2x);
		--heroSectionPadRight: var(--sp2x);
		--catNameMargBot: var(--sp2x);
		--personNameMargBot: var(--sp1x);
		--personOccupationMargBot: var(--sp5x);
		--dateMargBot: var(--sp6x);
		--shareNameMargBot: var(--sp2x);
		--iconFontSize: var(--sp4-5x);
		--socialItemMargRight: var(--sp2x);
		--sectionMargBot: var(--sp10x);

		flex-direction: column-reverse;

		.heroInfo {
			width: 100%;
		}

		.heroImage {
			width: 100%;
			margin-bottom: var(--sp4x);
		}
	}
`
export default IndividualHeroSectionStyle
