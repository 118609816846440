import React, { memo as Memo } from 'react'

//* Style
import InfoSectionStyle from './style'

const InfoSection = Memo(({ infoData, ...props }) => {
	return (
		<InfoSectionStyle>
			<div dangerouslySetInnerHTML={{ __html: infoData }} className='contentDescription'></div>
		</InfoSectionStyle>
	)
})

export default InfoSection
