import styled from 'styled-components'

const RoadmapStyle = styled.div`
	--titleMargin: 0 0 var(--sp13x) 0;
	--contentWrapPadding: var(--sp19x);
	--yearMarginLeft: var(--sp22x);
	--contentDescriptionMargin: var(--sp5x) 0 var(--sp22x) 0;
	--iconSize: var(--sp12x);
	--contentWidth: 90%;

	.title {
		margin: var(--titleMargin);
	}
	.customContainer {
		width: var(--contentWidth);

		.year {
			width: 100%;
		}

		.yearsNum {
			position: absolute;
			width: fit-content;
			top: 0;
			right: calc(100% + var(--contentWrapPadding) + var(--yearMarginLeft));
		}

		.contentWrap {
			.contentBlock {
				.contentTitle {
					display: inline-block;
				}
				position: relative;
				.iconCont {
					position: absolute;
					top: -8%;
					left: calc(0% - var(--contentWrapPadding) - calc(var(--iconSize) / 2));
					i {
						&::before {
							font-size: var(--iconSize);
						}
					}
				}
				.link {
					display: inline-block;
				}
			}
			padding-left: var(--contentWrapPadding);
			border-left: 2px solid purple;

			.contentDescription {
				margin: var(--contentDescriptionMargin);
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--titleMargin: 0 0 var(--sp9x) 0;
		--contentWrapPadding: var(--sp14x);
		--yearMarginLeft: var(--sp16x);
		--contentDescriptionMargin: var(--sp4x) 0 var(--sp13x) 0;
		--iconSize: var(--sp10x);
		--contentWidth: 88%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--titleMargin: 0 0 var(--sp8x) 0;
		--contentWrapPadding: var(--sp13x);
		--yearMarginLeft: var(--sp15x);
		--contentDescriptionMargin: var(--sp3x) 0 var(--sp12x) 0;
		--iconSize: var(--sp8x);
		--contentWidth: 86%;

		.contentWrap {
			width: 100%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMargin: 0 0 var(--sp7x) 0;
		--contentWrapPadding: var(--sp12x);
		--yearMarginLeft: var(--sp14x);
		--contentDescriptionMargin: var(--sp3x) 0 var(--sp10x) 0;
		--iconSize: var(--sp5x);
		--contentWidth: 90%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--titleMargin: 0 0 var(--sp6x) 0;
		--contentWrapPadding: var(--sp10x);
		--yearMarginLeft: var(--sp13x);
		--contentDescriptionMargin: var(--sp3x) 0 var(--sp9x) 0;
		--iconSize: var(--sp5x);
		--contentWidth: 104%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--titleMargin: 0 0 var(--sp6x) 0;
		--contentWrapPadding: var(--sp10x);
		--yearMarginLeft: var(--sp13x);
		--contentDescriptionMargin: var(--sp3x) 0 var(--sp9x) 0;
		--iconSize: var(--sp5x);
		--contentWidth: 100%;
		.contentWrap {
			width: 100%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMargin: 0 0 var(--sp5x) 0;
		--contentWrapPadding: var(--sp4x);
		--yearMarginLeft: var(--sp5x);
		--contentDescriptionMargin: var(--sp2x) 0 var(--sp9x) 0;
		--iconSize: var(--sp5x);
		--contentWidth: 100%;
		.contentWrap {
			width: 100%;
		}
	}
`

export default RoadmapStyle
