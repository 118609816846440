import styled from 'styled-components'

const PaginationStyle = styled.div`
  --pagePad: var(--sp16x);
  --subTitleMargBot: var(--sp5x);
  padding: 0 var(--pagePad);

  .hrBackCol {
    background-color: var(--purple);
    margin-bottom: var(--subTitleMargBot);
  }

  .PagItem {
    width: 33.3%;

    .btnNext {
      line-height: 1;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--purple);
        transform: translateY(-100%);
      }

      &:hover {
        &:after {
          animation: monFrame var(--trTime2) linear;
        }
      }
    }

    @keyframes monFrame {
      0% {
        width: 0%;
      }

      100% {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
          props.theme.mediaQuery.DesktopSizeM}) {
    --pagePad: var(--sp11-5x);
    --subTitleMargBot: var(--sp4x);
  }

  @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
          props.theme.mediaQuery.DesktopSizeS}) {
    --pagePad: var(--sp7x);
    --subTitleMargBot: var(--sp3x);
  }

  @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
          props.theme.mediaQuery.DesktopSizeXS}) {
    --pagePad: var(--sp6-5x);
    --subTitleMargBot: var(--sp3x);
  }

  @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
          props.theme.mediaQuery.LaptopSize}) {
    --pagePad: var(--sp5x);
    --subTitleMargBot: var(--sp3x);
  }

  @media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
          props.theme.mediaQuery.TabletSize}) {
    --pagePad: var(--sp4-5x);
    --subTitleMargBot: var(--sp3x);
  }

  @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
    --pagePad: var(--sp2x);
    --subTitleMargBot: var(--sp3x);
  }
`

export default PaginationStyle