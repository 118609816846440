import React, { memo as Memo, useState, useRef, useEffect, useMemo, useCallback } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Container, Title, Text, LottieAnimation, AnimationWrapp } from 'components/common'

import DataSecurityStyle from './style'

import authentication from 'lottieAnimation/home/authentication.json'
import servers from 'lottieAnimation/home/servers.json'
import encription from 'lottieAnimation/home/encription.json'
import payments from 'lottieAnimation/home/payments.json'
import strongDataGoverment from 'lottieAnimation/home/strongDataGoverment.json'
import compliant from 'lottieAnimation/home/compliant.json'

const DataSecuritySection = Memo(({ data, preloader, selectedLang, ...props }) => {
	const Lottie = [authentication, servers, encription, payments, strongDataGoverment, compliant]

	//! States
	const [secObj, setSecObj] = useState()
	const [lottieObj, setLottieObj] = useState([])
	const [identificator, setIdentificator] = useState(0)

	//! Refs
	const items = useRef([])

	useEffect(() => {
		if (data && items?.current.length) {
			setSecObj(items?.current)
		}
	}, [items, data, selectedLang])

	const startAnimeArr = useCallback(
		(x) => {
			setLottieObj(x)
			setIdentificator(x.length)
		},
		[identificator]
	)

	const itemsLottie = useMemo(() => {
		if (data.length) {
			return data.map((item, ind) => {
				return (
					<div className='items' key={ind} ref={(el) => (items.current[ind] = el)}>
						<LottieAnimation animData={Lottie[ind]} isPlay={lottieObj[ind]} autoplay={false} loop={true} className='titleMaxMargBot' />
						<Text className={'titleMinMargBot h6 PolySansMedianRegular purple'} text={item.title} />
						<Text className={'pM PolySansNeutralRegular purple'} text={item.text} />
					</div>
				)
			})
		}
	}, [data, lottieObj, identificator])

	return data ? (
		<Container className='containerPaddBottom '>
			<AnimationWrapp state={secObj} startAnimeArr={startAnimeArr}>
				<DataSecurityStyle>
					<Title className={'titleWrap purple'} text={['dataSecurity', 'youCan']} />
					<div className='ItemsWrap FlexBoxContainer SpaceBetween'>{itemsLottie}</div>
				</DataSecurityStyle>
			</AnimationWrapp>
		</Container>
	) : (
		''
	)
})

export default withLanguageContext(DataSecuritySection, ['selectedLang'])
