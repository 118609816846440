import styled from 'styled-components'

const CollapseItemStyle = styled.div`
	--collapseWrapperPad: var(--sp7x);
	--descMargTop: var(--sp6x);
	--iconWidth: var(--sp6-5x);
	--collapseWrapItemMB: var(--sp6x);
	--iconHeight: 5px;

	.collapseWrapper {
		padding: var(--collapseWrapperPad);
		background-color: var(--white);
		box-shadow: -16px 16px 0 var(--purpleLite);
		margin-bottom: var(--collapseWrapItemMB);

		.accordion {
			box-shadow: none;
			background: transparent;
			margin-right: var(--collapseWrapperPad);

			.collapseDesc {
				margin-top: var(--descMargTop);
				margin-right: var(--collapseWrapperPad);
			}

			.accordionSummary {
				margin: 0;
				padding: 0;
				position: relative;

				.accordionTitle {
					margin-right: var(--collapseWrapperPad);
				}

				.icon {
					position: absolute;
					right: calc(var(--collapseWrapperPad) * (-1));
					width: var(--iconWidth);
					height: var(--iconHeight);
					background-color: var(--purple);
					top: 50%;

					&.active {
						&:after {
							transform: revert;
						}
					}
				}

				.icon::after {
					content: '';
					width: var(--iconWidth);
					height: var(--iconHeight);
					background-color: var(--purple);
					position: absolute;
					transform: rotate(90deg);
					opacity: 1;
					transition: all 350ms ease;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--collapseWrapperPad: var(--sp6x);
		--descMargTop: var(--sp6x);
		--iconWidth: var(--sp5x);
		--collapseWrapItemMB: var(--sp4x);
		--iconHeight: 4px;
		.collapseWrapper {
			box-shadow: -12px 12px 0 var(--purpleLite);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--collapseWrapperPad: var(--sp5x);
		--descMargTop: var(--sp5x);
		--iconWidth: var(--sp4-5x);
		--collapseWrapItemMB: var(--sp3x);
		--iconHeight: 4px;
		.collapseWrapper {
			box-shadow: -12px 12px 0 var(--purpleLite);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--collapseWrapperPad: var(--sp4x);
		--descMargTop: var(--sp4x);
		--iconWidth: var(--sp4x);
		--collapseWrapItemMB: var(--sp3x);
		--iconHeight: 4px;
		.collapseWrapper {
			box-shadow: -8px 8px 0 var(--purpleLite);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--collapseWrapperPad: var(--sp3x);
		--descMargTop: var(--sp3x);
		--iconWidth: var(--sp3x);
		--collapseWrapItemMB: var(--sp3x);
		--iconHeight: 4px;
		.collapseWrapper {
			box-shadow: -5px 5px 0 var(--purpleLite);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--collapseWrapperPad: var(--sp3x);
		--descMargTop: var(--sp3x);
		--iconWidth: var(--sp3x);
		--collapseWrapItemMB: var(--sp3x);
		--iconHeight: 4px;
		.collapseWrapper {
			box-shadow: -5px 5px 0 var(--purpleLite);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--collapseWrapperPad: var(--sp3x);
		--descMargTop: var(--sp3x);
		--iconWidth: var(--sp3x);
		--collapseWrapItemMB: var(--sp3x);
		--iconHeight: 4px;
		.collapseWrapper {
			box-shadow: -5px 5px 0 var(--purpleLite);
		}
	}
`
export default CollapseItemStyle
