import styled from 'styled-components'

const LoveWeprofitStyle = styled.div`
	.ItemsWrap {
		> span {
			display: inline-block;
		}
		.comp-title.h3 {
			white-space: nowrap;
			svg {
				position: absolute;
				top: 90%;
				left: 0;
				width: 0%;
				transition: width var(--trTime3) ease;
				height: calc(var(--h3) / 3);
				z-index: -1;
			}
		}
		&.active {
			.comp-title.h3 {
				svg {
					width: 100%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.ItemsWrap {
			.comp-title.h3 {
				font-size: var(--sp2-5x);
			}
			&.active {
				.comp-title.h3 {
					font-size: var(--sp2-5x);
				}
			}
		}
	}
`
export default LoveWeprofitStyle
