import styled from 'styled-components'

const BlogFilterStyle = styled.div`
	--pagePadRight: var(--sp15-5x);
	--listItemPadTop: var(--sp2x);
	--listItemPadRight: var(--sp5x);
	--filterListMargBot: var(--sp7x);
	padding-right: var(--pagePadRight);
	margin-bottom: var(--filterListMargBot);

	.filterItem {
		padding: var(--listItemPadTop) var(--listItemPadRight);
		background-color: var(--white);
		border: 2px solid var(--white);
		white-space: pre;
		transition: 0.02s;
		&:first-child {
			border-radius: 5px 0 0 5px;
		}
		&:last-child {
			border-radius: 0 5px 5px 0;
			margin-right: 0;
		}

		&.active {
			p {
				color: var(--purpleLite);
				font-family: PolySansNeutralItalic;
			}
			background-color: var(--purpleMediumLite);
			border-radius: 5px;
			border: 2px solid var(--purpleLite);
			pointer-events: none;
		}

		@media (hover: hover) {
			&:hover {
				& > p {
					color: var(--purpleLite);
				}
				border-radius: 5px;
				border: 2px solid var(--purpleLite);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--filterListMargRight: var(--spSize);
		--pagePadRight: var(--sp11-5x);
		--listItemPadTop: var(--sp2x);
		--filterListMargBot: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--filterListMargRight: var(--spSize);
		--pagePadRight: var(--sp7x);
		--listItemPadTop: var(--sp2x);
		--filterListMargBot: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--filterListMargRight: var(--spSize);
		--pagePadRight: var(--sp6-5x);
		--listItemPadTop: var(--sp2x);
		--listItemPadRight: var(--sp4x);
		--filterListMargBot: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--filterListMargRight: var(--spSize);
		--pagePadRight: var(--sp5x);
		--listItemPadTop: var(--sp2x);
		--filterListMargBot: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--filterListMargRight: var(--spSize);
		--pagePadRight: var(--sp4-5x);
		--listItemPadTop: var(--sp2x);
		--filterListMargBot: var(--sp4x);
		.filterListPadRight {
			padding-right: 34px;
		}

		overflow-x: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
		.mobileStyle {
			width: 100%;
			padding: 0 34px;
		}

		.filterItem {
			margin-top: var(--spSize);

			&.active {
				pointer-events: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--pagePadRight: 0;
		--listItemPadTop: var(--sp2x);
		--listItemPadRight: var(--sp3-5x);
		--filterListMargBot: var(--sp4x);

		.filterListPadRight {
			padding-right: 16px;
		}

		overflow-x: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
		.mobileStyle {
			width: 100%;
			padding: 0 16px;
		}

		.filterItem {
			margin-top: var(--spSize);

			&.active {
				pointer-events: none;
			}
		}
	}
`
export default BlogFilterStyle
