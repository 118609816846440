import React, { memo as Memo, useMemo, useCallback, useRef, useEffect } from 'react'

import { gsap } from 'gsap'

// Components
import { Container, Text, Icon } from 'components/common'

// style
import RoadmapStyle from './style'

const Roadmap = Memo(({ data, ...props }) => {
	const contRef = useRef()
	const iconsRef = useRef([])

	const storeItems = useMemo(() => {
		return data.map((el, ind) => (
			<div className='contentBlock' key={ind}>
				<Text tag={'h4'} className={'h4 purple PolySansBulkyRegular yearsNum'} text={el.year} />
				<Text tag={'h5'} className={'h5 contentTitle PolySansMedianRegular purple'} text={el.title} />
				{el.link ? (
					<a href='#' target='_blank' className='link purpleLite h5 PolySansMedianRegular'>
						&nbsp;{el.link}
					</a>
				) : null}

				{el.description === undefined ? null : (
					<div>
						<Text tag={'p'} className={'pL contentDescription PolySansNeutralRegular purple'} text={el.description} />
					</div>
				)}
				<div className='iconCont' ref={(el) => (iconsRef.current[ind] = el)}>
					<Icon className={el.icon} />
				</div>
			</div>
		))
	}, [data])

	const sectionAnime = useCallback(() => {
		const elTop = contRef?.current?.getBoundingClientRect()
		const pointStart = window.innerHeight

		if (elTop?.top < pointStart) {
			if (elTop.top + window.innerHeight / 2 > 0 && elTop.top > 0) {
				gsap.to(iconsRef.current[0], { rotate: `${elTop.top / 20}` })
				gsap.to(iconsRef.current[1], { x: `-${elTop.top / 30}` })
				gsap.to(iconsRef.current[2], { rotate: `-${elTop.top / 15}` })
				gsap.to(iconsRef.current[3], { rotate: `-${elTop.top / 25}` })
				gsap.to(iconsRef.current[4], { rotate: `${elTop.top / 10}` })
			} else {
				gsap.to(iconsRef.current[0], { rotate: `${elTop.top / -20}` })
				gsap.to(iconsRef.current[1], { x: `${elTop.top / -30}` })
				gsap.to(iconsRef.current[2], { rotate: `${elTop.top / -15}` })
				gsap.to(iconsRef.current[3], { rotate: `${elTop.top / -25}` })
				gsap.to(iconsRef.current[4], { rotate: `${elTop.top / -10}` })
			}
		}
	}, [iconsRef])

	useEffect(() => {
		window.addEventListener('scroll', sectionAnime)

		return () => {
			window.removeEventListener('scroll', sectionAnime)
		}
	}, [sectionAnime])
	return (
		<RoadmapStyle>
			<Container>
				<Text tag={'h2'} className={'р2 purple title'} text={'Roadmap'} />
				<div className='customContainer FlexBox' ref={contRef}>
					<div className='year'></div>
					<div className='contentWrap'>{storeItems}</div>
				</div>
			</Container>
		</RoadmapStyle>
	)
})

export default Roadmap
