import React, { memo as Memo, useCallback } from 'react'

//* Components
import { Container, Text, Button, Icon, Title, RequestPopup } from 'components/common'

//* HOCs
import { withLanguageContext, withUIContext } from 'context'

//* Style
import CustomerTypeStyle from './style'

const CustomerTypeSection = Memo(({ data, preloader, openPopup, ...props }) => {
    const openPopupFun = useCallback(() => {
        openPopup(<RequestPopup />)
    }, [])
    return data ? (
        <CustomerTypeStyle>
            {data.map((item, ind) => {
                return (
                    <Container className='containerPaddBottom' key={ind}>
                        <div className='CustomerType overflowHidden'>
                            <div className='CustomerTypeTop FlexBoxContainer'>
                                <div className='CustomerInfo'>
                                    <div className='FlexBox AlignMiddle titleMaxMargBot'>
                                        <Text className={'h2 PolySansMedianRegular purple'} tag={'h2'} text={item.title} />
                                        {ind === 2 ? (
                                            <Text className={'h6 PolySansNeutralItalic purple comingSoon'} tag={'p'} text={'(coming soon)'} />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <Text className={'pL PolySansMedianRegular purple'} text={item.text} />
                                </div>
                                {item.img ? (
                                    <div className='videoCont'>
                                        <video className='video' width='100%' height='100%' playsInline autoPlay={true} muted loop={true}>
                                            <source src={item.img} type='video/mp4' />
                                        </video>
                                    </div>
                                ) : (
                                    ''
                                )}

                                <div className='BenefitsWrap FlexBoxContainer'>
                                    <Text className={'h6 PolySansNeutralItalic purple titleMaxMargBot'} text={'benefits'} />
                                    <div className='FlexBoxContainer SpaceBetween'>
                                        {item.benefitsItem.map((i, k) => {
                                            return (
                                                <div className='benefitsItem FlexBox' key={k}>
                                                    <Icon className='icon-Star pM purple'>&nbsp;</Icon>
                                                    <Text tag={'div'} className={'pL PolySansNeutralRegular purple'}>
                                                        <div dangerouslySetInnerHTML={{ __html: i }} />
                                                    </Text>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            {ind !== data.length - 1 ? (
                                <Button onClick={openPopupFun} wrapClassName={'btnMaxWidth purple'}>
                                    <Title type={'btn'} text={['requestAn', 'onboarding']} fontSize={'pM'} className={'whiteDark'} />
                                </Button>
                            ) : (
                                ''
                            )}
                        </div>
                    </Container>
                )
            })}
        </CustomerTypeStyle>
    ) : (
        ''
    )
})

export default withLanguageContext(withUIContext(CustomerTypeSection, ['openPopup'], ['translate', 'selectedLang']))
