import styled from 'styled-components'

const PricingAnimationStyle = styled.div`
	--animationSectionMargBot: var(--sp22-5x);
	--sectionWrapTB: var(--sp18x);
	--sectionWrapRL: var(--sp18x);
	--secWrapWidth: 50.5%;
	--secWrapAnime: calc(100% - var(--secWrapWidth));
	--wrapInfoTitleMB: var(--sp5x);

	--protoTypeRight: 75px;
	--protoTypeTop: -55px;
	--protoTypeHeight: 71.9%;
	--protoTypeWidth: 682px;

	--protoTypeTextTop: 60px;
	--protoTypeTextRight: 230px;

	.thirdSection {
		margin-bottom: var(--animationSectionMargBot);
		background-color: var(--green);

		.sectionWrap {
			padding: var(--sectionWrapTB) var(--sectionWrapRL);

			.secWrapInfo {
				.Title {
					margin-bottom: var(--wrapInfoTitleMB);
				}

				width: var(--secWrapWidth);

				.purple {
					color: var(--purple);

					span {
						font-family: PolySansNeutralItalic;
					}

					span:last-child {
						font-family: PolySansMedianRegular;
					}
				}

				.secondDesc {
					p {
						font-size: var(--h6);
						font-family: PolySansNeutralRegular;
						color: var(--purple);
					}

					span {
						position: relative;
						z-index: 1;
						font-family: PolySansMedianSemiBoldItalic;
						white-space: nowrap;

						&:after {
							position: absolute;
							content: '';
							background-image: url('/images/svg/under_line.svg');
							width: 0%;
							background-repeat: no-repeat;
							background-size: cover;
							z-index: -1;
							height: 60%;
							top: 50%;
							left: 0%;
							transform: translateY(-50%);
							transition: all var(--trTime2) ease;
						}
					}
				}
				&.active {
					.secondDesc {
						span {
							&:after {
								width: 105%;
							}
						}
					}
				}
			}

			.secWrapAnime {
				width: var(--secWrapAnime);

				.absoluteWrap {
					position: absolute;
					transform: rotate(-45deg);
					right: var(--protoTypeRight);
					top: var(--protoTypeTop);

					.imageCont {
						padding-top: var(--protoTypeHeight);
						width: var(--protoTypeWidth);
					}
				}

				.protoTypeText {
					position: absolute;
					transform: rotate(-45deg);
					top: var(--protoTypeTextTop);
					right: var(--protoTypeTextRight);

					> div {
						text-align: center;
					}

					.whiteDark:last-child {
						span {
							font-size: var(--pL);
							font-family: PolySansNeutralRegular;
						}

						span:last-child {
							font-family: PolySansNeutralItalic;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--animationSectionMargBot: var(--sp20x);
		--sectionWrapTB: var(--sp13x);
		--sectionWrapRL: var(--sp13x);
		--secWrapWidth: 56.3%;
		--secWrapAnime: calc(100% - var(--secWrapWidth));
		--wrapInfoTitleMB: var(--sp4x);

		--protoTypeRight: 55px;
		--protoTypeTop: -15px;
		--protoTypeHeight: 71.9%;
		--protoTypeWidth: 517px;

		--protoTypeTextTop: 85px;
		--protoTypeTextRight: 185px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--animationSectionMargBot: var(--sp17-5x);
		--sectionWrapTB: var(--sp12x);
		--sectionWrapRL: var(--sp9x);
		--secWrapWidth: 54.2%;
		--secWrapAnime: calc(100% - var(--secWrapWidth));
		--wrapInfoTitleMB: var(--sp3x);

		--protoTypeRight: 50px;
		--protoTypeTop: -45px;
		--protoTypeHeight: 71.9%;
		--protoTypeWidth: 445px;

		--protoTypeTextTop: 30px;
		--protoTypeTextRight: 140px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--animationSectionMargBot: var(--sp15x);
		--sectionWrapTB: var(--sp11x);
		--sectionWrapRL: var(--sp8x);
		--secWrapWidth: 49%;
		--secWrapAnime: calc(100% - var(--secWrapWidth));
		--wrapInfoTitleMB: var(--sp3x);

		--protoTypeRight: 50px;
		--protoTypeTop: -45px;
		--protoTypeHeight: 71.9%;
		--protoTypeWidth: 445px;

		--protoTypeTextTop: 30px;
		--protoTypeTextRight: 165px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--animationSectionMargBot: var(--sp12-5x);
		--sectionWrapTB: var(--sp8x);
		--sectionWrapRL: var(--sp7x);
		--secWrapWidth: 62%;
		--secWrapAnime: calc(100% - var(--secWrapWidth));
		--wrapInfoTitleMB: var(--sp2x);

		--protoTypeRight: 40px;
		--protoTypeTop: -20px;
		--protoTypeHeight: 71.9%;
		--protoTypeWidth: 344px;

		--protoTypeTextTop: 40px;
		--protoTypeTextRight: 90px;

		.protoTypeText {
			width: 100%;
			text-align: center;
		}

		.secondDesc {
			padding-right: 91px;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--animationSectionMargBot: var(--sp12-5x);
		--sectionWrapTB: var(--sp8x);
		--sectionWrapRL: var(--sp7x);
		--secWrapWidth: 86%;
		--secWrapAnime: calc(100% - var(--secWrapWidth));
		--wrapInfoTitleMB: var(--sp2x);

		--protoTypeRight: 10px;
		--protoTypeTop: 80px;
		--protoTypeHeight: 71.9%;
		--protoTypeWidth: 344px;

		--protoTypeTextTop: 145px;
		--protoTypeTextRight: 95px;

		.thirdSection {
			.sectionWrap {
				padding: var(--sectionWrapTB) var(--sectionWrapRL) var(--sp18x);
				.secondDesc {
					padding-right: 252px;
				}
				.secWrapAnime {
					.protoTypeText {
						width: 280%;
						text-align: center;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--animationSectionMargBot: var(--sp10x);
		--sectionWrapTB: var(--sp5x);
		--sectionWrapRL: var(--sp4x);
		--secWrapWidth: 100%;
		--secWrapAnime: 100%;
		--wrapInfoTitleMB: var(--sp2x);

		--protoTypeRight: 10px;
		--protoTypeTop: 25px;
		--protoTypeHeight: 71.9%;
		--protoTypeWidth: 292px;

		--protoTypeTextTop: 85px;
		--protoTypeTextRight: 65px;

		.thirdSection {
			.sectionWrap {
				padding: var(--sectionWrapTB) var(--sectionWrapRL) var(--sp34x);
			}
		}
	}
`
export default PricingAnimationStyle
