import styled from 'styled-components'

const PricingFilterStyle = styled.div`
	--filterItemWidth: var(--sp55-5x);
	--filterItemTB: var(--sp4x);
	--filterItemRL: var(--sp11x);
	--filteredListMargBot: var(--sp7x);

	--secondSectionMargBot: var(--sp22-5x);
	--intermediateDistance: var(--sp2x);

	.filterWrap {
		margin-bottom: var(--filteredListMargBot);

		.filteredList {
			background-color: var(--white);

			.pricingFilterItem {
				width: var(--filterItemWidth);
				padding: var(--filterItemTB) var(--filterItemRL);
				text-align: center;
				word-break: break-word;
				p {
					line-height: 1;
				}
			}

			.filteredListDouble {
				pointer-events: none;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 50%;
				border-radius: 5px;
				border: 2px solid var(--purpleLite);
				transition: all var(--trTime3) ease;
				transform: translateX(0%);

				.filteredList {
					width: 200%;
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					transition: all var(--trTime3) ease;

					.pricingFilterItem {
						padding: 0;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				&.active {
					transform: translateX(100%);

					.filteredList {
						transform: translateX(-50%);
					}
				}
			}
		}
	}

	.filterWrap {
		opacity: 0;
		transform: translateY(100%);
		transition: all var(--trTime2) ease;
	}

	.secondSection {
		margin-bottom: var(--secondSectionMargBot);

		.PricingListItem {
			width: calc(50% - var(--intermediateDistance));
			background-color: var(--white);
			opacity: 0;
			transform: translateY(50%);
		}
	}

	&.activeAnimation {
		.filterWrap {
			opacity: 1;
			transform: translateY(0%);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--filterItemWidth: var(--sp41x);
		--filterItemTB: var(--sp2-5x);
		--filterItemRL: var(--sp8x);
		--filteredListMargBot: var(--sp6x);

		--secondSectionMargBot: var(--sp20x);
		--intermediateDistance: var(--sp0-75x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--filterItemWidth: var(--sp41x);
		--filterItemTB: var(--sp2-5x);
		--filterItemRL: var(--sp8x);
		--filteredListMargBot: var(--sp5x);

		--secondSectionMargBot: var(--sp17-5x);
		--intermediateDistance: var(--sp1-25x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--filterItemWidth: var(--sp35x);
		--filterItemTB: var(--sp2-5x);
		--filterItemRL: var(--sp6x);
		--filteredListMargBot: var(--sp5x);

		--secondSectionMargBot: var(--sp15x);
		--intermediateDistance: var(--sp1-25x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--filterItemWidth: var(--sp28x);
		--filterItemTB: var(--sp2x);
		--filterItemRL: var(--sp4x);
		--filteredListMargBot: var(--sp4x);

		--secondSectionMargBot: var(--sp12-5x);
		--intermediateDistance: var(--sp1-25x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--filterItemWidth: var(--sp28x);
		--filterItemTB: var(--sp2x);
		--filterItemRL: var(--sp4x);
		--filteredListMargBot: var(--sp4x);

		--secondSectionMargBot: var(--sp12-5x);
		--intermediateDistance: var(--sp1-25x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--filterItemWidth: var(--sp23-5x);
		--filterItemTB: var(--sp2x);
		--filterItemRL: var(--sp2x);
		--filteredListMargBot: var(--sp4x);

		--secondSectionMargBot: var(--sp10x);
		--intermediateDistance: -50%;

		.secondSection {
			.PricingListItem:first-child {
				margin-bottom: var(--sp3x);
			}
		}
	}
`
export default PricingFilterStyle
