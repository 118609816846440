import { useCallback } from 'react'

import { Text, Button, Title, Icon, RequestPopup } from 'components/common'

import { withLanguageContext, withUIContext } from 'context'

//* Style
import PricingItemStyle from './style'

const PricingItem = ({ data, openPopup }) => {
	const openPopupFun = useCallback(() => {
		openPopup(<RequestPopup />)
	}, [])

	return (
		<PricingItemStyle className='overflowHidden'>
			<div className='PricingListItemWrap'>
				<Text tag={'h2'} className={'h2 PolySansMedianRegular purple title'} text={data.title} />
				<Text tag={'pL'} className={'pL PolySansNeutralRegular purple desc'} text={data.desc} />
				<div className='borderBottom'></div>
				<div className='FlexBox freeItem'>
					{data.plan.price && <Text tag={'h4'} className={'h4 PolySansNeutralRegular green afterStyle'} text={`${data.plan.price}`} />}
					<Text tag={'h4'} className={'h4 PolySansNeutralRegular green uppercase'} text={data.plan.title} />
				</div>
				<Text tag={'h6'} className={'h6 PolySansNeutralItalic purpleLite freeForeverItem'} text={data.plan.subtitle} />
				<div className='listContainer'>
					{data.plan.conditions.map((cond, k) => (
						<div className='FlexBox AlignMiddle chackWrap' key={k}>
							<Icon className='icon-chack' />
							<Text tag={'h6'} className={'h6 PolySansNeutralRegular purple'} text={cond} />
						</div>
					))}
				</div>
			</div>
			<Button onClick={openPopupFun} className={'purple'}>
				<Title type={'btn'} text={['requestAn', 'onboarding']} fontSize={'pL'} className={'whiteDark'} />
			</Button>
		</PricingItemStyle>
	)
}

export default withLanguageContext(withUIContext(PricingItem, ['openPopup']), ['selectedLang', 'translate'])
