import styled from 'styled-components'

const LoveWeprofitStyle = styled.div`
	--titleMarg: var(--sp13x);
	--itemSpace: var(--sp11x);
	--itemWidth: calc(25% - var(--itemSpace));

	.titleWrap {
		margin-bottom: var(--titleMarg);
	}
	.ItemsWrap {
		.items {
			width: var(--itemWidth);
			.LottieWrapp {
				padding: 18%;
				display: inline-block;
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarg: var(--sp5x);
		--itemSpace: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarg: var(--sp8x);
		--itemSpace: var(--sp6-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMarg: var(--sp6-5x);
		--itemSpace: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--titleMarg: var(--sp6x);
		--itemSpace: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--titleMarg: var(--sp6x);
		--itemSpace: var(--sp3x);
		--itemWidth: calc(40% - var(--itemSpace));

		.ItemsWrap {
			.items {
				margin-bottom: var(--sp8x);
				&:nth-last-child(-n + 2) {
					margin-bottom: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMarg: var(--sp5x);
		--itemSpace: var(--sp3x);
		--itemWidth: calc(50% - var(--itemSpace));

		.ItemsWrap {
			.items {
				margin-bottom: var(--sp8x);
				&:nth-last-child(-n + 2) {
					margin-bottom: 0;
				}
			}
		}
	}
`
export default LoveWeprofitStyle
