import styled from 'styled-components'

const PricingItemStyle = styled.div`
	--btnPadTB: var(--sp4x);
	--btnPadRL: var(--sp10x);
	--listItemWrapPadTB: var(--sp10x);
	--listItemWrapPadRL: var(--sp10x);
	--titleMargBot: var(--sp4x);
	--freeItemMargBot: var(--sp3x);
	--freeForeverItemMargBot: var(--sp9x);
	--chackMargBot: var(--sp5-5x);
	--chackMargRight: var(--sp4x);
	--chackSize: var(--sp3-5x);

	display: flex;
	flex-direction: column;
	flex: 1 1 auto;

	.PricingListItemWrap {
		padding: var(--listItemWrapPadTB) var(--listItemWrapPadRL);
		flex: 1 1 auto;

		.title {
			margin-bottom: var(--titleMargBot);
		}

		.desc {
			margin-bottom: var(--titleMargBot);
		}

		.borderBottom {
			border-bottom: 2px solid var(--purpleLite);
			margin-bottom: var(--titleMargBot);
		}

		.freeItem {
			margin-bottom: var(--freeItemMargBot);
		}

		.afterStyle {
			display: flex;
			align-items: center;
			text-decoration: 3px line-through;
			&::after {
				content: '';
				width: 16px;
				margin: 0 20px;
				background-color: var(--green);
				height: 3px;
			}
		}

		.freeForeverItem {
			margin-bottom: var(--freeForeverItemMargBot);
		}

		.listContainer {
			.chackWrap {
				margin-bottom: var(--chackMargBot);
				i {
					font-size: var(--chackSize);
					color: var(--purple);
					margin-right: var(--chackMargRight);
				}
				&.chackWrap:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.btn {
		padding: var(--btnPadTB) var(--btnPadRL);
		text-align: center;
		width: 100%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--btnPadTB: var(--sp3x);
		--btnPadRL: var(--sp8x);
		--listItemWrapPadTB: var(--sp8x);
		--listItemWrapPadRL: var(--sp8x);
		--titleMargBot: var(--sp3x);
		--freeItemMargBot: var(--sp3x);
		--freeForeverItemMargBot: var(--sp7x);
		--chackMargBot: var(--sp4x);
		--chackMargRight: var(--sp2x);
		--chackSize: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--btnPadTB: var(--sp2-5x);
		--btnPadRL: var(--sp7x);
		--listItemWrapPadTB: var(--sp7x);
		--listItemWrapPadRL: var(--sp7x);
		--titleMargBot: var(--sp3x);
		--freeItemMargBot: var(--sp3x);
		--freeForeverItemMargBot: var(--sp5x);
		--chackMargBot: var(--sp3-5x);
		--chackMargRight: var(--sp2x);
		--chackSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--btnPadTB: var(--sp2-5x);
		--btnPadRL: var(--sp6x);
		--listItemWrapPadTB: var(--sp6x);
		--listItemWrapPadRL: var(--sp6x);
		--titleMargBot: var(--sp3x);
		--freeItemMargBot: var(--sp3x);
		--freeForeverItemMargBot: var(--sp5x);
		--chackMargBot: var(--sp3x);
		--chackMargRight: var(--sp2x);
		--chackSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--btnPadTB: var(--sp2-5x);
		--btnPadRL: var(--sp5x);
		--listItemWrapPadTB: var(--sp5x);
		--listItemWrapPadRL: var(--sp4x);
		--titleMargBot: var(--sp2x);
		--freeItemMargBot: var(--sp2x);
		--freeForeverItemMargBot: var(--sp4-5x);
		--chackMargBot: var(--sp2-8x);
		--chackMargRight: var(--sp2x);
		--chackSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--btnPadTB: var(--sp2-5x);
		--btnPadRL: var(--sp5x);
		--listItemWrapPadTB: var(--sp5x);
		--listItemWrapPadRL: var(--sp4x);
		--titleMargBot: var(--sp2x);
		--freeItemMargBot: var(--sp2x);
		--freeForeverItemMargBot: var(--sp8-5x);
		--chackMargBot: var(--sp2-8x);
		--chackMargRight: var(--sp2x);
		--chackSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--btnPadTB: var(--sp2-5x);
		--btnPadRL: var(--sp4x);
		--listItemWrapPadTB: var(--sp5x);
		--listItemWrapPadRL: var(--sp4x);
		--titleMargBot: var(--sp2x);
		--freeItemMargBot: var(--sp2x);
		--freeForeverItemMargBot: var(--sp5x);
		--chackMargBot: var(--sp2-8x);
		--chackMargRight: var(--spSize);
		--chackSize: var(--sp2x);

		.PricingListItemWrap {
			.afterStyle {
				display: flex;
				align-items: center;
				text-decoration: 2px line-through;
				&::after {
					content: '';
					width: 12px;
					margin: 0 12px;
					background-color: var(--green);
					height: 2px;
				}
			}
		}
	}
`
export default PricingItemStyle
