import styled from 'styled-components'

const ImprintPrintStyle = styled.div`
	--pageTitleMargBot: var(--sp7x);
	--sectionTitleMargBot: var(--sp5x);
	--lineHeightM: 1.6;

	div {
		margin-bottom: var(--containerMarginBottom);

		&:last-child {
			margin-bottom: 0;
		}
	}

	.pageTitle {
		margin-bottom: var(--pageTitleMargBot);
	}

	.sectionTitleMargBot {
		margin-bottom: var(--sectionTitleMargBot);
	}

	.pM {
		line-height: calc(var(--pM) * var(--lineHeightM));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--pageTitleMargBot: var(--sp6x);
		--sectionTitleMargBot: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--pageTitleMargBot: var(--sp5x);
		--sectionTitleMargBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--pageTitleMargBot: var(--sp5x);
		--sectionTitleMargBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--pageTitleMargBot: var(--sp4x);
		--sectionTitleMargBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--pageTitleMargBot: var(--sp4x);
		--sectionTitleMargBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--pageTitleMargBot: var(--sp4x);
		--sectionTitleMargBot: var(--sp2x);
	}
`
export default ImprintPrintStyle
