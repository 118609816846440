import styled from 'styled-components'

const HowItWorksStyle = styled.div`
	--InfoWrappWidth: 42%;
	--LotieWrappWidth: 49%;

	.InfoWrapp {
		width: var(--InfoWrappWidth);
		margin-bottom: var(--containerMarginTop);
		> div {
			position: sticky;
			top: 10%;
		}
	}

	.ItemsWrap {
		width: var(--LotieWrappWidth);
		.items {
			margin-bottom: var(--containerMarginTop);
			.LottieWrapp {
				padding-top: 58%;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--InfoWrappWidth: 41%;
		--LotieWrappWidth: 50%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--InfoWrappWidth: 49%;
		--LotieWrappWidth: 49%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--InfoWrappWidth: 49%;
		--LotieWrappWidth: 62%;

		.ItemsWrap {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.items {
				width: var(--LotieWrappWidth);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--InfoWrappWidth: 100%;
		--LotieWrappWidth: 100%;
	}
`
export default HowItWorksStyle
