import React, { memo as Memo, useRef, useEffect, useCallback, useState } from 'react'
import { gsap } from 'gsap'

//* JSON's
import config from 'helpers/_config'

//* Components
import { Container, Title, Text, Button, Image } from 'components/common'

//* Style
import LatestPressStyle from './style'

const LatestPress = Memo(({ data, preloader, ...props }) => {
	const [start, setStart] = useState(true)

	const contRefTop = useRef()
	const contRefBottom = useRef()
	const itemCircle = useRef()
	const itemOval = useRef()
	const itemHalf = useRef()

	const sectionAnimeTop = useCallback(() => {
		const elTop = contRefTop?.current?.getBoundingClientRect()
		const pointStart = window.innerHeight

		if (elTop.top + 700 < pointStart && start) {
			gsap.to(itemHalf.current, { y: -100 })
			gsap.to(itemOval.current, { y: 0 })
			gsap.to(itemCircle.current, { y: 90 })
			setStart(false)
		}
	}, [start, itemHalf, itemOval, itemCircle])

	const sectionAnimeBottom = useCallback(() => {
		const elTop = contRefBottom?.current?.getBoundingClientRect()
		const pointStart = window.innerHeight

		if (elTop?.top < pointStart && !start) {
			if (elTop.top + window.innerHeight > 0 && elTop.top > 0) {
				gsap.to(itemHalf.current, { y: `${(elTop.top - pointStart) / 2}` })
				gsap.to(itemOval.current, { y: `${(elTop.top - pointStart) / 1}` })
				gsap.to(itemCircle.current, { y: `${(elTop.top - pointStart) / 2}` })
			} else {
				gsap.to(itemHalf.current, { y: `-${(elTop.top - pointStart) / 2}` })
				gsap.to(itemOval.current, { y: `-${(elTop.top - pointStart) / 1}` })
				gsap.to(itemCircle.current, { y: `-${(elTop.top - pointStart) / 2}` })
			}
		}
	}, [start, itemHalf, itemOval, itemCircle])

	useEffect(() => {
		window.addEventListener('scroll', sectionAnimeTop)
		window.addEventListener('scroll', sectionAnimeBottom)

		return () => {
			window.removeEventListener('scroll', sectionAnimeTop)
			window.removeEventListener('scroll', sectionAnimeBottom)
		}
	}, [sectionAnimeTop, sectionAnimeBottom])

	return (
		<Container className='containerPaddBottom ' ref={contRefTop}>
			<LatestPressStyle className='FlexBoxContainer SpaceBetween relativeWrap'>
				<div className='titleWrap'>
					<Title className={' titleMaxMargBot purple'} text={['Latest', 'press']} />
				</div>
				<div className='leftSec FlexBoxColumn'>
					<div className='FlexBoxColumn contentWrap'>
						<div className='textContent FlexBoxColumn'>
							<Text
								tag={'p'}
								className={'pL purple leftDesc'}
								text={
									'Often, companies see this question as an either-or proposition. However, these approaches can also be brought together. Having IT and software competence in your own company is essential and not replaceable.'
								}
							/>
							<div>
								<Text tag={'p'} className={'subDesc PolySansMedianRegular purple pS'} text={'Matteo Emmanuello, COO'} />
								<Text tag={'p'} className={'date PolySansNeutralItalic purple pS'} text={'16 Nov 2021'} />
							</div>
						</div>
					</div>
					<Button link={'/'} className={'purple'} wrapClassName={'btnContTopMarg hidden'}>
						<Title type={'btn'} text={['Visit our', 'news page']} fontSize={'pM'} className={`white`} />
					</Button>
				</div>

				<div className='rightSection FlexBoxColumn'>
					<div className='FlexBoxColumn contentWrap'>
						<div className='textContent FlexBoxColumn'>
							<Text
								tag={'p'}
								className={'pL purple rightTitle'}
								text={
									'Digital transformation is omnipresent at the moment. But what does this transition mean for your business, what is just talk and what and with whom can actually be implemented?'
								}
							/>
							<div>
								<Text tag={'p'} className={'subDesc PolySansMedianRegular purple pS'} text={'Matteo Emmanuello, COO'} />
								<Text tag={'p'} className={'date PolySansNeutralItalic purple pS'} text={'21 Oct 2021'} />
							</div>
						</div>
					</div>

					<Button link={config.routes.blog.path} className={'purple'} wrapClassName={'btnContTopMarg'}>
						<Title type={'btn'} text={['Visit our', 'news page']} fontSize={'pM'} className={`white`} />
					</Button>
				</div>
				<div className='overflowHidden'>
					<Image src={'/images/home/figure/ellipse.svg'} alt='ellipse_image' ref={itemHalf} />
					<Image src={'/images/home/figure/oval.svg'} alt='oval_image' ref={itemOval} />
					<Image src={'/images/home/figure/circle.svg'} alt='circle_image' ref={itemCircle} />
				</div>
			</LatestPressStyle>
			<div ref={contRefBottom}></div>
		</Container>
	)
})

export default LatestPress
