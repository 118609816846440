import React, { memo as Memo } from 'react'

//* HOCs
import { withLanguageContext } from 'context'

//* Components
import { Text, Image, ShareLink } from 'components/common'

//* Style
import IndividualHeroSectionStyle from './style'

const IndividualHeroSection = Memo(({ postData, ...props }) => {
	return (
		<IndividualHeroSectionStyle className='FlexBox'>
			<div className='heroInfo FlexBoxColumn AlignCenter'>
				<div className='catName'>
					<Text tag={'pL'} className='pL purpleLite PolySansNeutralRegular' text={postData.category.name} />
				</div>
				<div className='personName'>
					<Text tag={'pL'} className='pL purple PolySansMedianRegular' text={postData.person_name} />
				</div>
				<div className='personOccupation'>
					<Text tag={'pL'} className='pL purple PolySansNeutralItalic' text={postData.person_occupation} />
				</div>
				<div className='FlexBox date'>
					<Text
						tag={'pS'}
						className='pS PolySansNeutralRegular purple'
						text={`${postData.reading_time} ${props.translate('minuteRead')}`}
					/>
					<Text tag={'pS'} className='pS PolySansNeutralRegular purple' text={postData.date} />
				</div>
				<div className='shareName'>
					<Text tag={'pS'} className='pS purple PolySansNeutralRegular' text={'SHARE THIS ON'} />
				</div>
				<div className='socIcon FlexBox'>
					<ShareLink />
				</div>
			</div>
			<div className='heroImage'>
				<Image src={postData.featured_image} alt='Hero Image' />
			</div>
		</IndividualHeroSectionStyle>
	)
})

export default withLanguageContext(IndividualHeroSection, ['translate'])
